import React from "react";
import { formatTimestamp } from "../../components/Shared/SharedMethods";
import SharedTooltip from "../../components/Shared/SharedTooltip";
import {
  MdOutlineDelete,
  MdOutlineEdit,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import Loader from "../../Loader";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "../../components/Shared/CustomPagination";
import { useTranslation } from "react-i18next";
import { getAttendanceList } from "../../store/slices/HrSlice";

export default function AttendanceTable({ data }) {
  //   const datebody = (rowData) => {
  //     return formatTimestamp(rowData.created_at);
  //   };

  const { isLoading } = useSelector((state) => state.hr);
  const dispatch = useDispatch();

  const onPageChange = (newPage, newRowsPerPage) => {
    dispatch(
      getAttendanceList({
        page: newPage,
        per_page: newRowsPerPage,
      })
    );
  };

  const templateBody = (rowData) => (
    <div className="flex gap-1">
      <SharedTooltip content="view" id="my-tooltip-1" />
      <SharedTooltip id="my-tooltip-2" content="edit" />
      <SharedTooltip id="my-tooltip-3" content="delete" />
      <MdOutlineRemoveRedEye
        size={25}
        data-tooltip-id="my-tooltip-1"
        className="!p-1 !text-black hover:cursor-pointer"
      />
      <MdOutlineEdit
        size={25}
        data-tooltip-id="my-tooltip-2"
        className="!p-1 !text-black hover:cursor-pointer"
      />
      <MdOutlineDelete
        size={25}
        data-tooltip-id="my-tooltip-3"
        className="!p-1 !text-black hover:cursor-pointer"
      />
    </div>
  );
  const { t } = useTranslation();
  const sequenceBodyTemplate = (rowData, options) => {
    return options.rowIndex + 1 + (data?.page - 1) * data?.per_page;
  };
  return (
    <>
      <DataTable value={data?.data} tableStyle={{ minWidth: "50rem" }}>
        <Column
          body={sequenceBodyTemplate}
          header={t("no")}
          style={{ width: "3rem" }}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary !rounded-tl-md",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />
        <Column
          field="full_name"
          header={t("name")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
          body={(rowData) => (
            <div>
              {rowData.employee.first_name} {rowData.employee.last_name}
            </div>
          )}
        />

        <Column
          field="days"
          header={t("days")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="month"
          header={t("month")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="year"
          header={t("year")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          body={templateBody}
          header={t("actions")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary !rounded-tr-md",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />
      </DataTable>
      <Loader showLoader={isLoading} />

      {data?.data?.length > 0 && (
        <CustomPagination
          currentPage={data?.page}
          rowsPerPage={data?.per_page}
          totalRecords={data?.total_pages}
          totalRecord={data?.total_record}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
}
