import React, { useEffect } from "react";
import SharedButton from "../../components/Shared/SharedButton";
import { Dialog } from "primereact/dialog";
import { Formik, Form } from "formik";
import SharedDropdown from "../../components/Shared/SharedDropdown";
import SharedInputText from "../../components/Shared/SharedInputText";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";
import CustomSearchDropdown from "../../components/Shared/CustomSearchDropdown";
import { getAllEmployees } from "../../store/slices/EmployeeSlice";
import {
  CreateNewAttendance,
  getAttendanceList,
} from "../../store/slices/HrSlice";

export default function CreateAttendance({
  createDialogVisible,
  setCreateDialogVisible,
}) {
  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.employee);
  const { allEmployees } = useSelector((state) => state.employee);
  const { t } = useTranslation();

  const listEmployees = allEmployees?.data
    ? allEmployees.data.map((employee) => ({
        id: employee.id,
        name: `${employee.first_name} ${employee.last_name}`,
      }))
    : [];

  // year-month-days
  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: 10 }, (_, i) => ({
    id: currentYear - i,
    name: (currentYear - i).toString(),
  }));
  const monthOptions = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];
  const dayOptions = Array.from({ length: 31 }, (_, i) => ({
    id: i + 1,
    name: (i + 1).toString(),
  }));

  const initialValues = {
    employee_id: "",
    year: "",
    month: "",
    days: "",
  };

  const validationSchema = Yup.object({
    employee_id: Yup.number().required(t("required")),
    year: Yup.number().required(t("required")),
    month: Yup.number().required(t("required")),
    days: Yup.number().required(t("required")),
  });

  const status_option = [
    { value: "Present", label: "Present" },
    { value: "Absent", label: "Absent" },
  ];

  return (
    <>
      <Dialog
        header={t("CreateAttendance")}
        visible={createDialogVisible}
        onHide={() => {
          if (!createDialogVisible) return;
          setCreateDialogVisible(false);
        }}
        pt={{
          root: { className: "w-full lg:w-10/12 mx-4 overflow-auto" },
          header: { className: "!p-2 !bg-darkBg !text-white" },
          content: { className: "!p-3" },
          closeButton: {
            className: "hover:!bg-black/20 !outline-none !shadow-none",
          },
          closeButtonIcon: { className: "!text-white" },
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (data, { resetForm }) => {
            try {
              const resultAction = await dispatch(CreateNewAttendance(data));
              if (CreateNewAttendance.fulfilled.match(resultAction)) {
                resetForm();
                setCreateDialogVisible(false);
                dispatch(getAttendanceList({ page: 1, per_page: 10 }));
              }
            } catch (error) {
              console.error("Error creating leave:", error);
            }
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4 w-full">
                <CustomSearchDropdown
                  name="employee_id"
                  placeholder={`${t("select")} ${t("employee")}`}
                  options={listEmployees}
                  label={`${t("employee")} ${t("name")}`}
                  className={"!w-full"}
                />

                <CustomSearchDropdown
                  label={t("year")}
                  name="year"
                  placeholder={`${t("select")} ${t("year")}`}
                  options={yearOptions}
                  className="!w-full"
                />

                <CustomSearchDropdown
                  label={t("month")}
                  name="month"
                  placeholder={`${t("select")} ${t("month")}`}
                  options={monthOptions}
                  className="!w-full"
                />

                <CustomSearchDropdown
                  label={t("days")}
                  name="days"
                  placeholder={`${t("select")} ${t("days")}`}
                  options={dayOptions}
                  className="!w-full"
                />
              </div>
              <div className="flex justify-end gap-6 mt-6">
                <SharedButton
                  type="button"
                  icon="pi pi-times"
                  label={t("Cancel")}
                  className="!p-1.5 !bg-red-400 hover:!bg-red-400"
                  onClick={() => {
                    setCreateDialogVisible(false);
                  }}
                />
                <SharedButton
                  type="submit"
                  icon="pi pi-check"
                  label={t("Submit")}
                  className="!p-1.5 bg-gradient"
                />
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
      <Loader showLoader={isLoading} />
    </>
  );
}
