import React from "react";
import { useField } from "formik";
import { Dropdown } from "primereact/dropdown";
import { t } from "i18next";

export default function SharedDropdown({
  label = "",
  name = "",
  className = "",
  options = [],
  optionLabel = "label",
  optionValue = "value",
  ...props
}) {
  const [field, meta] = useField(name);
  
  return (
    <div className="flex flex-col gap-1 w-full">
      <label htmlFor={name} className="text-base font-medium">
        {label}
      </label>
      <Dropdown
        options={options}
        optionLabel={optionLabel}
        optionValue={optionValue}
        placeholder={`${t("select")} ${label}`}
        className={`!w-full ${className} !p-0 !border-gray-300 rounded-md hover:!border-primary !outline-none !shadow-none`}
        pt={{
          input: { className: "!w-full !p-1.5" },
          list: { className: "!py-0" },
        }}
        {...field}
        {...props}
      />
      {meta.error && meta.touched && (
        <p className="text-sm text-red-500">{meta.error}</p>
      )}
    </div>
  );
}
