import React, { useEffect, useRef, useState } from "react";
import SharedButton from "../../components/Shared/SharedButton";
import CashFlowCard from "./CashFlowCard";
import {
  getBankFlow,
  getCashFlow,
  GetCashFlow,
} from "../../store/slices/ReportSlice";
import { useDispatch, useSelector } from "react-redux";
import BankFlowCard from "./BankFlowCard";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader";

export const getOneMonthAgo = (currentDate) => {
  const date = new Date(currentDate);
  date.setMonth(date.getMonth() - 1);
  return date.toISOString().split("T")[0];
};

export default function CashInflow() {
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [startDate, setStartDate] = useState(getOneMonthAgo(endDate));
  const { loading, cashFlow, bankFlow } = useSelector((state) => state.reports);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const balanceSheetRef = useRef(null);
  const iframeRef = useRef(null);
  // loading, cashFlow, bankFlow
  const handleSubmit = () => {
    if (startDate && endDate) {
      const payload = {
        start_date: startDate,
        end_date: endDate,
      };
      dispatch(getCashFlow(payload));
      dispatch(getBankFlow(payload));
    }
  };

  useEffect(() => {
    const payload = {
      start_date: startDate,
      end_date: endDate,
    };
    dispatch(getCashFlow(payload));
    dispatch(getBankFlow(payload));
  }, []);

  const handlePrint = () => {
    const printContents = balanceSheetRef.current.innerHTML;
    const iframe = iframeRef.current;
    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write("<html><head><title>Balance Sheet</title>");
    doc.write(
      '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css">'
    );

    doc.write("<style>");
    doc.write(`
      @media print {
        @page {
          margin: 20px 0 20px 0; /* Removes the default margins */
        }
        body {
          margin: 1cm; /* Adjust the margins to your needs */
        }
        /* Hide browser print headers and footers */
        header, footer {
          display: none;
        }
      }
    `);
    doc.write("</style>");

    doc.write("</head><body>");
    doc.write(printContents);
    doc.write("</body></html>");
    doc.close();

    iframe.onload = () => {
      iframe.contentWindow.print();
    };
  };

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex gap-5 flex-wrap">
          <div className="flex  items-center gap-2">
            <label className="text-sm">{t("StartDate")}</label>
            <input
              required
              type="date"
              name="start_date"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
              id="dobDate"
              autoComplete="off"
              className="p-[0.33rem] border rounded-md"
            />
          </div>
          <div className="flex items-center gap-2">
            <label className="text-sm">{t("EndDate")}</label>
            <input
              required
              type="date"
              name="end_date"
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
              id="dobDate"
              autoComplete="off"
              className="p-[0.33rem] border rounded-md"
            />
          </div>
          <div className="">
            <SharedButton
              label={t("Submit")}
              className="bg-gradient"
              onClick={handleSubmit}
              disabled={!startDate || !endDate}
            />
          </div>
        </div>

        <div className="">
          <SharedButton
            label={`${t("Print")} `}
            icon="pi pi-print"
            onClick={handlePrint}
            className="bg-gradient"
          />
        </div>
      </div>

      <div className="this_div" ref={balanceSheetRef}>
        {cashFlow && bankFlow ? (
          <>
            <CashFlowCard cashFlow={cashFlow} />
            <BankFlowCard bankFlow={bankFlow} />
          </>
        ) : null}
      </div>

      <iframe ref={iframeRef} style={{ display: "none" }}></iframe>
      <Loader />
    </div>
  );
}
