import React, { useEffect, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { useField } from "formik";

const CustomSearchDropdown = ({
  label,
  name,
  options,
  placeholder,
  className,
  onChange,
}) => {
  const [field, meta, helpers] = useField(name);

  useEffect(() => {
    if (typeof field.value === "object" && field.value.id) {
      helpers.setValue(field.value.id);
    }
  }, []);

  const handleChange = (e) => {
    const selectedValue = e.value;
    helpers.setValue(selectedValue);

    if (onChange) {
      onChange({
        target: {
          name: name,
          value: selectedValue,
        },
      });
    }
  };

  const selectedOptionTemplate = (option) => {
    if (option) {
      return <div>{option.name}</div>;
    }
    return <span>{placeholder}</span>;
  };

  const optionTemplate = (option) => {
    return <div>{option.name}</div>;
  };

  return (
    <div className="flex flex-col gap-1 w-full">
      <label htmlFor={name} className="text-base font-medium">
        {label}
      </label>
      <Dropdown
        id={name}
        name={name}
        value={field.value}
        onChange={handleChange}
        options={options}
        optionLabel="name"
        optionValue="id"
        placeholder={placeholder}
        filter
        valueTemplate={selectedOptionTemplate}
        itemTemplate={optionTemplate}
        className={`w-full p-0 border-gray-300 rounded-md hover:border-primary outline-none shadow-none`}
        pt={{
          input: { className: "!w-full !p-1.5" },
          list: { className: "!py-0" },
          filterIcon: { className: "!right-[10px]" },
          root: { className: `${className} ` },
        }}
      />
      {meta.error && meta.touched && (
        <p className="text-sm text-red-500">{meta.error}</p>
      )}
    </div>
  );
};

export default CustomSearchDropdown;
