import React, { useEffect, useState } from "react";
import SharedTooltip from "../../components/Shared/SharedTooltip";
import SharedButton from "../../components/Shared/SharedButton";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";
import { getPayslipList } from "../../store/slices/PayslipSlice";
import PayslipTable from "./PayslipTable";
import CreatePayslip from "./CreatePayslip";

export default function PayslipMain() {
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPayslipList({ page: 1, per_page: 10 }));
  }, []);

  const { payslipList } = useSelector((state) => state.payslip);
  const { isLoading } = useSelector((state) => state.payslip);
  const { t } = useTranslation();

  return (
    <>
      <div>
        <div className="flex justify-between flex-col gap-3 sm:gap-0 sm:flex-row mb-3">
          <SharedTooltip
            content= {t("Keep_track_of_employees_payslips")} 
            position="left"
            id="Items"
          />
          <span
            data-tooltip-id="Items"
            className="font-semibold text-black/70 text-xl mt-2 "
          >
            {t("PAYSLIPS")}
            <span className="italic text-lg ml-1">
              ( 
                {payslipList?.total_record}
              <span className="text-xs font-bold italic"> {t("Entries")}</span>)
            </span>
          </span>

          {/* <SharedButton
            label={t("Generate Payslip")}
            icon="pi pi-plus"
            onClick={() => setCreateDialogVisible(true)}
            className="bg-gradient"
          /> */}
        </div>
        <PayslipTable data={payslipList} />
        <CreatePayslip
          setCreateDialogVisible={setCreateDialogVisible}
          createDialogVisible={createDialogVisible}
        />
      </div>
      <Loader showLoader={isLoading} />
    </>
  );
}
