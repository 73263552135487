import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SharedButton from "../../components/Shared/SharedButton";
import SharedTooltip from "../../components/Shared/SharedTooltip";
import {
  getInvoicesList,
  getSearchedInvoice,
} from "../../store/slices/InvoicesSlice";
import InvoiceTable from "./InvoiveTable";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../components/Shared/Search";
import { FaSearch } from "react-icons/fa";

export default function InvoicesMain() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const { invoicesList, isLoading } = useSelector((state) => state.invoice);
  const [searchValue, setSearchValue] = useState("");
  const [hasCalledList, setHasCalledList] = useState(false);
  const [filteredInvoices, setFilteredInvoices] = useState(null);
  const { t } = useTranslation();

  const handleSearch = async () => {
    if (searchValue.trim().length >= 8) {
      const data = await dispatch(
        getSearchedInvoice({ query: searchValue.trim() })
      );

      // Update the invoices list with the search results
      if (data?.data && data?.data.length > 0) {
        setFilteredInvoices(data);
      }
    } else {
      setFilteredInvoices(null);
      dispatch(getInvoicesList({ page: 1, per_page: 10 }));
    }
  };

  useEffect(() => {
    dispatch(getInvoicesList({ page: 1, per_page: 10 }));
  }, []);

  return (
    <div className="">
      <div className="flex justify-between mb-3">
        <SharedTooltip
          content={t("Keep_track_of_your_invoices")}
          position="left"
          id="Items"
        />
        <span
          data-tooltip-id="Items"
          className="font-semibold text-black/70 text-xl mt-2 "
        >
          {t("SALESINVOICES")}
          <span className="italic text-lg ml-1">
            ({invoicesList?.total_record || 0}
            <span className="text-xs font-bold italic">{t("Entries")} </span>)
          </span>
        </span>

        <div className="flex items-center gap-4">
          <div className="flex gap-2">
            <input
              placeholder={t(" Search by invoice number")}
              type="text"
              // className="placeholder:ml-1 block w-full rounded-md border-0 py-1.5 px-5 text-gray-900 ring-1 ring-inset !ring-gray-300 !placeholder:text-gray-400 focus:!ring-1 focus:!ring-inset focus:ring-orange-400 sm:text-sm sm:leading-6"
              className="border border-gray-300 rounded-lg px-2.5 py-1.5 w-64 hover:border-primary !outline-none !shadow-none"
              onChange={(e) => {
                setSearchValue(e.target.value);
                if (e.target.value.length < 8 && !hasCalledList) {
                  dispatch(getInvoicesList({ page: 1, per_page: 10 }));
                  setHasCalledList(true);
                } else if (e.target.value.length >= 8) {
                  setHasCalledList(false);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
            />
            <span
              className="!bg-primary !text-white rounded-md p-2.5"
              onClick={() => handleSearch()}
            >
              <FaSearch />
            </span>
          </div>

          <SharedButton
            label={t("CREATESALESINVOICES")}
            icon="pi pi-plus"
            onClick={() => navigate("/manager/invoices/create")}
            className="bg-gradient"
          />
        </div>
      </div>

      <InvoiceTable
        invoicesList={
          filteredInvoices?.length ? filteredInvoices : invoicesList
        }
      />
      <Loader showLoader={isLoading} />
    </div>
  );
}
