import React, { useEffect, useState } from "react";
import SharedTooltip from "../components/Shared/SharedTooltip";
import { useDispatch, useSelector } from "react-redux";
import SharedButton from "../components/Shared/SharedButton";
import BusinessModal from "./BusinessModal";
import {
  GetBusinesses,
  GetBusinessesList,
} from "../store/slices/BusinessSlice";
import BusinessTable from "./BusinessTable";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";

export default function MainBusiness() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetBusinessesList({ page: 1, per_page: 10 }));
  }, []);
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const { businessList, isLoading } = useSelector((state) => state.business);

  return (
    <>
      <div className="flex justify-between flex-col gap-3 sm:gap-0 sm:flex-row mb-3">
        <SharedTooltip
          content= {t("Keep_track_of_your_bussiness")} 
          position="left"
          id="Items"
        />
        <span
          data-tooltip-id="Items"
          className="font-semibold text-black/70 text-xl mt-2 "
        >
          {t("Companies")}
          <span className="italic text-lg ml-1">
            ({businessList?.total_record || 0}
            <span className="text-xs font-bold italic">{t("Entries")} </span>)
          </span>
        </span>
        <SharedButton
          label={t("CreateCompany")}
          icon="pi pi-plus"
          onClick={() => setCreateDialogVisible(true)}
          className="bg-gradient"
        />
      </div>
      <BusinessTable data={businessList} />
      <BusinessModal
        setCreateDialogVisible={setCreateDialogVisible}
        createDialogVisible={createDialogVisible}
        dispatch={dispatch}
      />
      <Loader showLoader={isLoading} />
    </>
  );
}
