import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import SharedInputText from "../../components/Shared/SharedInputText";
import SharedButton from "../../components/Shared/SharedButton";
import {
  createAccounts,
  getAccounts,
  getListAccounts,
  getSubTypesDropdown,
  getTypesDropdown,
} from "../../store/slices/AcountsSlice";
import { useDispatch, useSelector } from "react-redux";
import SharedDropdown from "../../components/Shared/SharedDropdown";
import CustomSearchDropdown from "../../components/Shared/CustomSearchDropdown";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function CreateAccount({
  createDialogVisible,
  setCreateDialogVisible,
}) {
  const dispatch = useDispatch();
  const lang = i18next.language;
  const { user } = useSelector((state) => state.auth);
  const { listAccounts, coaTypes, coaTypesBySubTypes } = useSelector(
    (state) => state.acount
  );
  const { isLoading } = useSelector((state) => state.acount);
  const [selectedType, setSelectedType] = useState("");
  const [subTypeOptions, setSubTypeOptions] = useState([]);
  const formikRef = useRef(null);
  // const listAccountsvalues = Object.entries(listAccounts.data).map(
  //   ([id, account]) => ({
  //     id: Number(account?.id),
  //     name: lang === "ar" ? account.name_ar : account.name_en,
  //   })
  // );
  // const coaTypesValues = Object.entries(coaTypes).map(([_, account]) => ({
  //   id: Number(account?.id),
  //   name: lang === "ar" ? account.name_ar : account.name_en,
  // }));
  const listAccountsvalues = listAccounts?.data
    ? Object.entries(listAccounts.data).map(([id, account]) => ({
        id: Number(account?.id),
        name: lang === "ar" ? account.name_ar : account.name_en,
        name_en:account.name_en,
        name_ar:account.name_ar,
      }))
    : [];

  const coaTypesValues = coaTypes?.data
    ? coaTypes.data.map((type) => ({
        id: Number(type.id),
        name: lang === "ar" ? type.name_ar : type.name_en,
        name_en:type.name_en,
        name_ar:type.name_ar,
      }))
    : [];

  const [subTypes, setSubTypes] = useState([]);
  useEffect(() => {
    if (coaTypesBySubTypes) {
      const mappedSubTypes = coaTypesBySubTypes.map((subType) => ({
        id: Number(subType.id),
        name: lang === "ar" ? subType.name_ar : subType.name_en,
        name_en:subType.name_en,
        name_ar:subType.name_ar,
      }));
      setSubTypes(mappedSubTypes);
    }
  }, [coaTypesBySubTypes, lang]);

  useEffect(() => {
    dispatch(getTypesDropdown());
    // dispatch(getListAccounts());
  }, [dispatch]);

  const category_option = [
    { value: "parent", label: lang === "ar" ? "أصل" : "Parent" },
    { value: "child", label: lang === "ar" ? "طفل" : "Child" },
  ];

  const initialValues = {
    name_en: "",
    name_ar: "",
    type: "",
    sub_type: "",
    balance: 0,
    branch_id: user?.branch_id,
    business_id: user?.business_id,
    category: "",
    parent_id: 0,
    description: "",

    type_en: "",
    type_ar: "",
    sub_type_en: "",
    sub_type_ar: "",
  };
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    name_en: yup.string().required(t("required")),
    name_ar: yup.string().required(t("required")),
    type: yup.string().required(t("required")),
    sub_type: yup.string().required(t("required")),
    balance: yup.number().required(t("required")),
    category: yup.string().required(t("required")),
    parent_id: yup.number(),
    description: yup.string().required(t("required")),
  });

  const handleSubmit = async (data, { resetForm }) => {
    const typeDetails = coaTypesValues.find((type) => type.id === data.type);
    const subTypeDetails = subTypes.find(
      (subType) => subType.id === data.sub_type
    );

    const formData = {
      ...data,
      type_en: typeDetails ? typeDetails.name_en : "",
      type_ar: typeDetails ? typeDetails.name_ar : "",
      sub_type_en: subTypeDetails ? subTypeDetails.name_en : "",
      sub_type_ar: subTypeDetails ? subTypeDetails.name_ar : "",
    };

    const resultAction = await dispatch(createAccounts(formData));
    if (createAccounts.fulfilled.match(resultAction)) {
      setCreateDialogVisible(false);
      resetForm();
      dispatch(getAccounts({ page: 1, per_page: 10 }));
    }
  };

  return (
    <>
      <Dialog
        header={t("CreateAccount")}
        visible={createDialogVisible}
        onHide={() => {
          if (!createDialogVisible) return;
          setCreateDialogVisible(false);
        }}
        pt={{
          root: { className: "w-full lg:w-10/12 mx-4 overflow-auto" },
          header: { className: "!p-2 !bg-darkBg !text-white" },
          content: { className: "!p-3" },
          closeButton: {
            className: "hover:!bg-black/20 !outline-none !shadow-none",
          },
          closeButtonIcon: { className: "!text-white" },
        }}
      >
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={(values) => {
            const errors = {};
            if (values.category === "child" && values.parent_id === 0) {
              errors.parent_id = "Account is required";
            } else if (values.category === "parent" && values.parent_id !== 0) {
              errors.parent_id = "Parent ID must be 0 for parent category";
            }
            return errors;
          }}
          // onSubmit={async (data, { resetForm }) => {
          //   const resultAction = await dispatch(createAccounts(data));
          //   if (createAccounts.fulfilled.match(resultAction)) {
          //     // dispatch(getListAccounts());
          //     dispatch(getAccounts({ page: 1, per_page: 10 }));
          //     resetForm();
          //     setCreateDialogVisible(false);
          //   }
          // }}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <>
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4 w-full">
                <SharedInputText
                  label={t("name_en")}
                  name={"name_en"}
                  placeholder={t("name_en")}
                  className="!p-1.5"
                />
                <SharedInputText
                  label={t("name_ar")}
                  name={"name_ar"}
                  placeholder={t("name_ar")}
                  className="!p-1.5"
                />

                <SharedDropdown
                  label={t("type")}
                  name="type"
                  options={coaTypesValues}
                  value={props.values.type}
                  onChange={(e) => {
                    const selectedTypeId = e.value;
                    props.setFieldValue("type", selectedTypeId);
                    dispatch(getSubTypesDropdown(selectedTypeId));
                  }}
                  optionLabel="name"
                  optionValue="id"
                  placeholder={`${t("select")} ${t("type")}`}
                />

                <SharedDropdown
                  label={t("sub_type")}
                  name="sub_type"
                  options={subTypes}
                  value={props.values.sub_type}
                  onChange={(e) => {
                    props.setFieldValue("sub_type", e.value);
                  }}
                  optionLabel="name"
                  optionValue="id"
                  placeholder={`${t("select")} ${t("subType")}`}
                />

                <SharedInputText
                  type={"number"}
                  label={t("balance")}
                  name={"balance"}
                  placeholder={`${t("enter")} ${t("balance")}`}
                  className="!p-1.5"
                />
                
                {/* <SharedDropdown
                  label={t("category")}
                  name={"category"}
                  options={category_option}
                  onChange={(e) => {
                    props.handleChange(e);
                    if (e.target.value === "parent") {
                      props.setFieldValue("parent_id", 0);
                    }
                  }}
                /> */}
                <SharedDropdown
                  label={t("category")}
                  name={"category"}
                  options={category_option}
                  onChange={(e) => {
                    props.handleChange(e);
                    if (e.target.value === "parent") {
                      props.setFieldValue("parent_id", 0);
                    } else if (e.target.value === "child") {
                      dispatch(getListAccounts());
                    }
                  }}
                />

                {/* {props?.values?.category === "child" && (
                  <CustomSearchDropdown
                    label={t("account")}
                    name={"parent_id"}
                    options={listAccountsvalues}
                    placeholder={`${t("select")}${t("account")}`}
                  />
                )} */}
                {props?.values?.category === "child" &&
                  listAccountsvalues.length > 0 && (
                    <CustomSearchDropdown
                      label={t("account")}
                      name={"parent_id"}
                      options={listAccountsvalues}
                      placeholder={`${t("select")}${t("account")}`}
                      className={"!w-full"}
                    />
                  )}

                <SharedInputText
                  label={t("description")}
                  name={"description"}
                  placeholder={t("description")}
                  className="!p-1.5"
                />
              </div>
              <div className="flex justify-end gap-6 mt-6">
                <SharedButton
                  icon="pi pi-times"
                  label={t("Cancel")}
                  className="!p-1.5 !bg-red-400 hover:!bg-red-400"
                  onClick={() => {
                    setCreateDialogVisible(false);
                    props.resetForm();
                  }}
                />
                <SharedButton
                  icon="pi pi-check"
                  label={t("Submit")}
                  className="!p-1.5 bg-gradient"
                  onClick={props.handleSubmit}
                />
              </div>
            </>
          )}
        </Formik>
      </Dialog>
      <Loader showLoader={isLoading} />
    </>
  );
}
