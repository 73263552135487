import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import validSupergitHIS from "../../api/validSupergitHIS";
import toast from "react-hot-toast";

export const createTransaction = createAsyncThunk(
  "createTransaction",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("createTransaction", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const getTransactions = createAsyncThunk(
  "getTransactions",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("listTransactions", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.message || "something went wrong"
      );
    }
  }
);

const initialState = {
  isLoading: false,
  transactions: [],
  page: 1,
  per_page: 10,
  total_pages: 0,
  total_record: 0,
};

const TransactionsSlice = createSlice({
  name: "transactions",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(createTransaction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createTransaction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      toast.success(action.payload.data || "Successful");
    });
    builder.addCase(createTransaction.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.error.message);
    });

    builder.addCase(getTransactions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTransactions.fulfilled, (state, action) => {
      state.transactions = action.payload.data;
      state.page = action.payload.page;
      state.per_page = action.payload.per_page;
      state.total_pages = action.payload.total_pages;
      state.isLoading = false;
      state.total_record = action.payload.total_record;
    });
    builder.addCase(getTransactions.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.error.message);
    });
  },
});

export default TransactionsSlice.reducer;
