import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SharedTooltip from "../../components/Shared/SharedTooltip";
import SharedButton from "../../components/Shared/SharedButton";
import Loader from "../../Loader";
import { getCostCenterList } from "../../store/slices/CostCenterSlice";
import CostCenterTable from "./CostCenterTable";
import CreateCostCenter from "./CreateCostCenter";

export default function CostCenterMain() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCostCenterList({ page: 1, per_page: 10 }));
  }, []);

  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const { costCenterList, isLoading } = useSelector((state) => state.costCenter);

  return (
    <>
      <div className="flex justify-between flex-col gap-3 sm:gap-0 sm:flex-row mb-3">
        <SharedTooltip
          content={t("Keep_track_of_your_CostCenters")}
          position="left"
          id="Items"
        />
        <span
          data-tooltip-id="Items"
          className="font-semibold text-black/70 text-xl mt-2 "
        >
          {t("costCenters")}
          <span className="italic text-lg ml-1">
            ({costCenterList?.total_record || 0}
            <span className="text-xs font-bold italic">{t("Entries")} </span>)
          </span>
        </span>
        <SharedButton
          label={t("CreateCostCenter")}
          icon="pi pi-plus"
          onClick={() => setCreateDialogVisible(true)}
          className="bg-gradient"
        />
      </div>
      <CostCenterTable data={costCenterList} />
      <CreateCostCenter
        setCreateDialogVisible={setCreateDialogVisible}
        createDialogVisible={createDialogVisible}
        dispatch={dispatch}
      />
      <Loader showLoader={isLoading} />
    </>
  );
}



