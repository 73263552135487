import React from "react";
import SharedTooltip from "../../components/Shared/SharedTooltip";
import {
  MdOutlineDelete,
  MdOutlineEdit,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import Loader from "../../Loader";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "../../components/Shared/CustomPagination";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "../../components/Shared/SharedMethods";
import { getPayrunList } from "../../store/slices/PayslipSlice";
import { formatDate } from "../Transactions/TransactionsTable";

export default function PayrunTable({ data }) {

  const datebody = (rowData) => {
    return formatTimestamp(rowData.payment_date);
  };

  const datebodi = (rowData) => {
    return formatTimestamp(rowData.pay_period);
  };

  const { isLoading } = useSelector((state) => state.employee);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onPageChange = (newPage, newRowsPerPage) => {
    dispatch(
      getPayrunList({
        page: newPage,
        per_page: newRowsPerPage,
      })
    );
  };
  const templateBody = (rowData) => (
    <div className="flex gap-1">
      <SharedTooltip content="view" id="my-tooltip-1" />
      <SharedTooltip id="my-tooltip-2" content="edit" />
      <SharedTooltip id="my-tooltip-3" content="delete" />
      <MdOutlineRemoveRedEye
        size={25}
        data-tooltip-id="my-tooltip-1"
        className="!p-1 !text-black hover:cursor-pointer"
      />
      <MdOutlineEdit
        size={25}
        data-tooltip-id="my-tooltip-2"
        className="!p-1 !text-black hover:cursor-pointer"
      />
      <MdOutlineDelete
        size={25}
        data-tooltip-id="my-tooltip-3"
        className="!p-1 !text-black hover:cursor-pointer"
      />
    </div>
  );

  const sequenceBodyTemplate = (rowData, options) => {
    return options.rowIndex + 1 + (data?.page - 1) * data?.per_page;
  };

  const basic_salary_body = (data) => {
    return (
      <>
        {data?.basic_salary} {t("SAR")}
      </>
    );
  };
  const allowances_body = (data) => {
    return (
      <>
        {data?.allowances} {t("SAR")}
      </>
    );
  };
  const deductions_body = (data) => {
    return (
      <>
        {data?.deductions} {t("SAR")}
      </>
    );
  };
  const net_salary_body = (data) => {
    return (
      <>
        {data?.net_salary} {t("SAR")}
      </>
    );
  };
  const gosi_contribution_body = (data) => {
    return (
      <>
        {data?.gosi_contribution} {t("SAR")}
      </>
    );
  };

  return (
    <>
      <DataTable value={data?.data} tableStyle={{ minWidth: "50rem" }}>

      <Column
          body={sequenceBodyTemplate}
          header={t("no")}
          style={{ width: "3rem" }}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary !rounded-tl-md",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="payment_date"
          body={(rowData) => formatDate(rowData.payment_date)}
          header={t("payment_date")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="pay_period"
          header={t("pay_period")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="full_name"
          header={t("name")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary !rounded-tl-md",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
          body={(rowData) => (
            <div>
              {rowData.employee.first_name} {rowData.employee.last_name}
            </div>
          )}
        />

        <Column
          field="basic_salary"
          body={basic_salary_body}
          header={t("basic_salary")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />
        <Column
          field="allowances"
          body={allowances_body}
          header={t("allowances")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="deductions"
          body={deductions_body}
          header={t("deductions")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="net_salary"
          body={net_salary_body}
          header={t("net_salary")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="gosi_contribution"
          body={gosi_contribution_body}
          header={t("gosi_contribution")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          body={templateBody}
          header={t("actions")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary !rounded-tr-md",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />
      </DataTable>
      {/* <Loader showLoader={isLoading} /> */}
      {data?.data?.length > 0 && (
        <CustomPagination
          currentPage={data?.page}
          rowsPerPage={data?.per_page}
          totalRecords={data?.total_pages}
          totalRecord={data?.total_record}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
}
