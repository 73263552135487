import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBalaceSheet } from "../../store/slices/ReportSlice";
import { useTranslation } from "react-i18next";
import PatientsVatReturn from "./PatientsVatReturn";
import CompaniesVatReturn from "./CompaniesVatReturn";

export default function VatReturnReport() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("patientsVatReturn");
  const dispatch = useDispatch();
  const { loading, balanceSheet } = useSelector((state) => state.reports);
  const tabList = [
    {
      label: t("Patients VAT Return"),
      comapre: "patientsVatReturn",
      command: () => setActiveTab("patientsVatReturn"),
    },
    {
      label: t("Companies VAT Return"),
      comapre: "companiesVatReturn",
      command: () => setActiveTab("companiesVatReturn"),
    },
  ];

  return (
    <div>
      <div>
        {" "}
        <div className=" col-span-5 p-2 rounded h-[75vh] overflow-auto">
          <ul className="grid grid-flow-col mb-2 text-center text-gray-500 bg-gray-100 border rounded p-1 shadow-inner">
            {tabList.map((tab) => (
              <li>
                <a
                  className={`flex justify-center py-2 cursor-pointer hover:text-primary ${
                    activeTab === tab?.comapre
                      ? " bg-gradient rounded shadow text-white "
                      : ""
                  }`}
                  onClick={() => tab?.command()}
                >
                  {tab.label}
                </a>
              </li>
            ))}
          </ul>
          <div>
            {activeTab === "patientsVatReturn" ? (
                <PatientsVatReturn />
            ) : activeTab === "companiesVatReturn" ? (
                <CompaniesVatReturn />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}


