import React from "react";
import SharedInputText from "../../components/Shared/SharedInputText";
import { Formik } from "formik";
import * as yup from "yup";
import {
  FaLock,
  FaRegUser,
  FaEnvelope,
  FaPhone,
  FaHome,
  FaIdCard,
  FaGlobe,
  FaUserAlt,
  FaCalendarAlt,
} from "react-icons/fa";
import SharedButton from "../../components/Shared/SharedButton";
import { useDispatch } from "react-redux";
import { SignUp } from "../../store/slices/AuthSlice";
import { useNavigate } from "react-router-dom";
import { ScrollPanel } from "primereact/scrollpanel";

export default function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    employee_id: "",
    email: "",
    password: "",
    cPassword: "",
    full_name: "",
    mobile_number: "",
    permanent_address: "",
    nationality: "",
    document_id: "",
    age: "",
    status_id: 1,
    role_id: 1,
  };
  const validationSchema = yup.object().shape({
    employee_id: yup
      .string()
      .required("Username is required")
      .label("Username"),
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required")
      .label("Email"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .label("Password"),
    cPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required")
      .label("Confirm Password"),
    full_name: yup
      .string()
      .required("Full name is required")
      .label("Full Name"),
    mobile_number: yup
      .string()
      .required("Mobile number is required")
      .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits")
      .label("Mobile Number"),
    permanent_address: yup
      .string()
      .required("Permanent address is required")
      .label("Permanent Address"),
    nationality: yup
      .string()
      .required("Nationality is required")
      .label("Nationality"),
    document_id: yup
      .string()
      .required("Document ID is required")
      .label("Document ID"),
    age: yup
      .number()
      .required("Age is required")
      .positive("Age must be a positive number")
      .integer("Age must be an integer")
      .min(18, "Age must be at least 18")
      .max(65, "Age must be less than or equal to 65")
      .label("Age"),
    status_id: yup
      .number()
      .required("Status ID is required")
      .oneOf([1, 2, 3], "Invalid status ID") // Assuming valid status IDs are 1, 2, and 3
      .label("Status ID"),
    role_id: yup
      .number()
      .required("Role ID is required")
      .oneOf([1, 2, 3], "Invalid role ID") // Assuming valid role IDs are 1, 2, and 3
      .label("Role ID"),
  });
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 h-full">
      <div className="hidden lg:block">
        <img src="/images/bannerSupergit.png" className="h-screen w-full" />
      </div>
      <ScrollPanel style={{ width: "100%", height: "100vh" }}>
        <div className="flex justify-center items-center flex-col my-6">
          <img src="/images/supergit-01.png" className="w-28 lg:w-36" />
          <p className="font-semibold text-2xl lg:text-3xl text-center my-3">
            Register To Your Account
          </p>
          <div className="flex justify-center w-full mt-4">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async (data) => {
                delete data.cPassword;
                const resultAction = await dispatch(SignUp(data));
                if (SignUp.fulfilled.match(resultAction)) {
                  navigate("/login");
                }
              }}
            >
              {(props) => (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3 w-full px-12">
                  <SharedInputText
                    label={"Full Name"}
                    name={"full_name"}
                    isIcon={true}
                    placeholder="Enter Full Name"
                    icon={<FaUserAlt />}
                    iconPos={"left"}
                    className="!p-1.5"
                  />

                  <SharedInputText
                    label={"Username"}
                    name={"employee_id"}
                    isIcon={true}
                    placeholder="Enter Username"
                    icon={<FaRegUser />}
                    iconPos={"left"}
                    className="!p-1.5"
                  />

                  <SharedInputText
                    label={"Email"}
                    name={"email"}
                    isIcon={true}
                    placeholder="Enter Email"
                    icon={<FaEnvelope />}
                    iconPos={"left"}
                    className="!p-1.5"
                  />

                  <SharedInputText
                    label={"Phone Number"}
                    name={"mobile_number"}
                    isIcon={true}
                    placeholder="Enter Phone Number"
                    icon={<FaPhone />}
                    iconPos={"left"}
                    className="!p-1.5"
                  />

                  <SharedInputText
                    label={"Age"}
                    name={"age"}
                    isIcon={true}
                    type={"number"}
                    placeholder="Enter Age"
                    icon={<FaCalendarAlt />}
                    iconPos={"left"}
                    className="!p-1.5"
                  />

                  <SharedInputText
                    label={"Permanent Address"}
                    name={"permanent_address"}
                    isIcon={true}
                    placeholder="Enter Permanent Address"
                    icon={<FaHome />}
                    iconPos={"left"}
                    className="!p-1.5"
                  />

                  <SharedInputText
                    label={"Nationality"}
                    name={"nationality"}
                    isIcon={true}
                    placeholder="Enter Nationality"
                    icon={<FaGlobe />}
                    iconPos={"left"}
                    className="!p-1.5"
                  />

                  <SharedInputText
                    label={"Document Id"}
                    name={"document_id"}
                    isIcon={true}
                    placeholder="Enter Document Id"
                    icon={<FaIdCard />}
                    iconPos={"left"}
                    className="!p-1.5"
                  />

                  <SharedInputText
                    label={"Password"}
                    name={"password"}
                    type="password"
                    isIcon={true}
                    placeholder="Enter Password"
                    icon={<FaLock />}
                    iconPos={"left"}
                    className="!p-1.5"
                  />

                  <SharedInputText
                    label={"Confirm Password"}
                    name={"cPassword"}
                    type="password"
                    isIcon={true}
                    placeholder="Enter Confirm Password"
                    icon={<FaLock />}
                    iconPos={"left"}
                    className="!p-1.5"
                  />

                  <SharedButton
                    label="Register"
                    className="!p-1.5  bg-gradient"
                    onClick={props.handleSubmit}
                  />
                </div>
              )}
            </Formik>
          </div>
        </div>
      </ScrollPanel>
      <div className="absolute bottom-0 left-0 m-3">
        <p className="bg-gray-100 py-1.5 px-3 text-gray-600 text-xs font-semibold rounded-full ">
          Super Git v2.1.4
        </p>
      </div>
    </div>
  );
}
