import React, { useEffect, useState } from "react";
import SharedTooltip from "../../components/Shared/SharedTooltip";
import SharedButton from "../../components/Shared/SharedButton";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";
import CreateEmployee from "./CreateEmployee";
import EmployeeTable from "./EmployeeTable";
import { getEmployeesList } from "../../store/slices/EmployeeSlice";
import { useNavigate } from "react-router-dom";

export default function EmployeeMain() {
  const navigate = useNavigate();
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [editingEmployee, setEditingEmployee] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEmployeesList({ page: 1, per_page: 10 }));
  }, []);

  const { employeesList } = useSelector((state) => state.employee);
  const { isLoading } = useSelector((state) => state.employee);
  const { t } = useTranslation();

  const handleEditEmployee = (employee) => {
    setEditingEmployee(employee);
    setCreateDialogVisible(true);
  };

  return (
    <>
      <div>
        <div className="flex justify-between flex-col gap-3 sm:gap-0 sm:flex-row mb-3">
          <SharedTooltip
            content={t("Keep_track_of_your_employees")}
            position="left"
            id="Items"
          />
          <span
            data-tooltip-id="Items"
            className="font-semibold text-black/70 text-xl mt-2 "
          >
            {t("EMPLOYEES")}
            <span className="italic text-lg ml-1">
              ({employeesList?.total_record}
              <span className="text-xs font-bold italic"> {t("Entries")}</span>)
            </span>
          </span>

          <SharedButton
            label={t("Create Employee")}
            icon="pi pi-plus"
            // onClick={() => setCreateDialogVisible(true)}
            onClick={() => navigate("/manager/payroll/create")}
            className="bg-gradient"
          />
        </div>
        <EmployeeTable
          data={employeesList}
          onEditEmployee={handleEditEmployee}
        />
        {/* <CreateEmployee
          setCreateDialogVisible={setCreateDialogVisible}
          createDialogVisible={createDialogVisible}
          editingEmployee={editingEmployee}
          setEditingEmployee={setEditingEmployee}
        /> */}
      </div>
      <Loader showLoader={isLoading} />
    </>
  );
}
