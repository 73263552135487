import React, { useEffect } from "react";
import SharedButton from "../../components/Shared/SharedButton";
import { Dialog } from "primereact/dialog";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
// import Loader from "../../Loader";
import { useTranslation } from "react-i18next";
import {
  CreateEmployeePayrun,
  getPayrunList,
} from "../../store/slices/PayslipSlice";
import SharedInputText from "../../components/Shared/SharedInputText";
import { getCashBankAccounts } from "../../store/slices/AcountsSlice";
import CustomSearchDropdown from "../../components/Shared/CustomSearchDropdown";
import i18next from "i18next";
import { getCurrentDateTime } from "../Transactions/CreateTransaction";
import { getCurrentMonth } from "./CreatePayroll";

export default function CreatePayrun({
  createDialogVisible,
  setCreateDialogVisible,
}) {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { cashBankAccounts } = useSelector((state) => state.acount);
  const { isLoading } = useSelector((state) => state.payslip);
  const { t } = useTranslation();
  const lang = i18next.language;

  const initialValues = {
    pay_period: getCurrentMonth(),
    // payment_date: getCurrentDateTime(),
    account_code: "",
    branch_id: user?.branch_id,
    business_id: user?.business_id,
  };

  const validationSchema = Yup.object({
    pay_period: Yup.string().required(t("required")),
    // payment_date: Yup.string().required(t("required")),
    account_code: Yup.string().required(t("required")),
    // debit_account_id: Yup.string().required(t("required")),
  });

  
  useEffect(() => {
    dispatch(getCashBankAccounts());
  }, []);

  const listAccountsvalues = cashBankAccounts?.data
    ? Object.entries(cashBankAccounts.data).map(([id, account]) => ({
        id: Number(account?.code),
        name: lang === "ar" ? account.name_ar : account.name_en,
      }))
    : [];

  return (
    <>
      <Dialog
        header={t("GeneratePayrun")}
        visible={createDialogVisible}
        onHide={() => {
          if (!createDialogVisible) return;
          setCreateDialogVisible(false);
        }}
        pt={{
          root: { className: "w-full lg:w-10/12 mx-4 overflow-auto" },
          header: { className: "!p-2 !bg-darkBg !text-white" },
          content: { className: "!p-3" },
          closeButton: {
            className: "hover:!bg-black/20 !outline-none !shadow-none",
          },
          closeButtonIcon: { className: "!text-white" },
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (data, { resetForm }) => {
            try {
              const resultAction = await dispatch(CreateEmployeePayrun(data));
              if (CreateEmployeePayrun.fulfilled.match(resultAction)) {
                resetForm();
                setCreateDialogVisible(false);
                dispatch(getPayrunList({ page: 1, per_page: 10 }));
              }
            } catch (error) {
              console.error("Error creating payrun:", error);
            }
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4 w-full">
                <SharedInputText
                  type={"month"}
                  label={t("pay_period")}
                  name={"pay_period"}
                  placeholder="Enter pay_period"
                  className="!p-1.5"
                />



                {/* <SharedInputText
                  type={"date"}
                  label={t("payment_date")}
                  name={"payment_date"}
                  placeholder="Enter payment_date"
                  className="!p-1.5"
                /> */}
                {/* <SharedInputText
                  type={"datetime-local"}
                  label={t("payment_date")}
                  name={"payment_date"}
                  placeholder="Enter payment_date"
                  className="!p-1.5"
                /> */}







                <CustomSearchDropdown
                  label={`${t("cashBank")} ${t("account")}`}
                  className="!w-full"
                  name={"account_code"}
                  options={listAccountsvalues}
                  placeholder={`${t("select")} ${t("account")}`}
                />

                {/* <CustomSearchDropdown
                  label={`${t("debit")} ${t("account")}`}
                  className="!w-full"
                  name={"debit_account_id"}
                  options={listAccountsvalues}
                  placeholder={`${t("select")} ${t("account")}`}
                /> */}
              </div>
              <div className="flex justify-end gap-6 mt-6">
                <SharedButton
                  type="button"
                  icon="pi pi-times"
                  label={t("Cancel")}
                  className="!p-1.5 !bg-red-400 hover:!bg-red-400"
                  onClick={() => {
                    setCreateDialogVisible(false);
                  }}
                />
                <SharedButton
                  type="submit"
                  icon="pi pi-check"
                  label={t("Submit")}
                  className="!p-1.5 bg-gradient"
                />
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
      {/* <Loader showLoader={isLoading} /> */}
    </>
  );
}
