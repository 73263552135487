import React, { useEffect, useState } from "react";
import SharedTooltip from "../../components/Shared/SharedTooltip";
import SharedButton from "../../components/Shared/SharedButton";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";
import {
  getSearchedVendor,
  getVendorList,
} from "../../store/slices/PurchaseSlice";
import CreateVendor from "./CreateVendor";
import VendorTable from "./VendorTable";
import { FaSearch } from "react-icons/fa";

export default function VendorMain() {
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVendorList({ page: 1, per_page: 10 }));
  }, []);

  const { vendorList } = useSelector((state) => state.purchase);
  const { isLoading } = useSelector((state) => state.purchase);
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState("");
  const [hasCalledList, setHasCalledList] = useState(false);
  const [filteredInvoices, setFilteredInvoices] = useState(null);

  const handleSearch = async () => {
    if (searchValue.trim().length >= 8) {
      const data = await dispatch(
        getSearchedVendor({ query: searchValue.trim() })
      );

      // Update the invoices list with the search results
      if (data?.data && data?.data.length > 0) {
        setFilteredInvoices(data);
      }
    } else {
      setFilteredInvoices(null);
      dispatch(getVendorList({ page: 1, per_page: 10 }));
    }
  };

  return (
    <>
      <div>
        <div className="flex justify-between flex-col gap-3 sm:gap-0 sm:flex-row mb-3">
          <SharedTooltip
            content={t("Keep_track_of_vendors")}
            position="left"
            id="Items"
          />
          <span
            data-tooltip-id="Items"
            className="font-semibold text-black/70 text-xl mt-2 "
          >
            {t("VENDORS")}
            <span className="italic text-lg ml-1">
              ({vendorList?.total_record}
              <span className="text-xs font-bold italic"> {t("Entries")}</span>)
            </span>
          </span>

          <div className="flex items-center gap-4">
            {/* <div className="flex gap-2">
              <input
                placeholder={t(" Search by name")}
                type="text"
                className="placeholder:ml-1 block w-full rounded-md border-0 py-1.5 px-5 text-gray-900 ring-1 ring-inset !ring-gray-300 !placeholder:text-gray-400 focus:!ring-1 focus:!ring-inset focus:ring-orange-400 sm:text-sm sm:leading-6"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  if (e.target.value.length < 8 && !hasCalledList) {
                    dispatch(getVendorList({ page: 1, per_page: 10 }));
                    setHasCalledList(true);
                  } else if (e.target.value.length >= 8) {
                    setHasCalledList(false);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              <span
                className="!bg-primary !text-white rounded-md p-2.5"
                onClick={() => handleSearch()}
              >
                <FaSearch />
              </span>
            </div> */}
            <div className="flex gap-2">
              <input
                placeholder={t(" Search by Name")}
                type="text"
                className="placeholder:ml-1 block w-full rounded-md border-0 py-1.5 px-5 text-gray-900 ring-1 ring-inset !ring-gray-300 !placeholder:text-gray-400 focus:!ring-1 focus:!ring-inset focus:ring-orange-400 sm:text-sm sm:leading-6"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  if (e.target.value.length < 8 && !hasCalledList) {
                    dispatch(getVendorList({ page: 1, per_page: 10 }));
                    setHasCalledList(true);
                  } else if (e.target.value.length >= 8) {
                    setHasCalledList(false);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              <span
                className="!bg-primary !text-white rounded-md p-2.5 cursor-pointer"
                onClick={() => handleSearch()}
              >
                <FaSearch />
              </span>
            </div>



            <SharedButton
              label={t("CreateVendor")}
              icon="pi pi-plus"
              onClick={() => setCreateDialogVisible(true)}
              className="bg-gradient"
            />
          </div>
        </div>
        {/* <VendorTable data={vendorList} /> */}
        <VendorTable 
        data={
          filteredInvoices?.length ? filteredInvoices : vendorList
        } />
        <CreateVendor
          setCreateDialogVisible={setCreateDialogVisible}
          createDialogVisible={createDialogVisible}
        />
      </div>
      <Loader showLoader={isLoading} />
    </>
  );
}
