import React, { useState } from 'react';
import { FaFileUpload } from 'react-icons/fa';

const AttachmentInput = ({ label, name, className, onChange, labelName, error }) => {
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState('');

  // Allowed file types: PDF, DOC, DOCX, XLS, XLSX
  const allowedTypes = [
    'application/pdf',               // PDF
    'application/msword',             // DOC
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX
    'application/vnd.ms-excel',       // XLS
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // XLSX
  ];

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const isValidFileType = allowedTypes.includes(selectedFile.type);

      console.log("File type:", selectedFile.type);
      console.log("Is valid file type:", isValidFileType);

      if (isValidFileType) {
        setFileName(selectedFile.name);
        setFile(selectedFile);
        convertToBase64(selectedFile);
        setFileError('');
      } else {
        setFile(null);
        setFileName('');
        setFileError('Invalid file type');
        if (onChange) onChange(null); // Notify parent about invalid file
      }
    }
  };

  const convertToBase64 = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64File = reader.result;
      if (onChange) onChange({ file, base64File }); // Notify parent with both file and base64
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="flex flex-col gap-1 w-full">
      <label className="text-base font-medium">{label}</label>
      <div className="flex items-center">
        <input
          type="file"
          name={name}
          id={name}
          className={`${className}`}
          onChange={handleFileChange}
        />
        <label
          htmlFor={name}
          className="cursor-pointer bg-white border border-gray-300 rounded-md p-[5px] flex items-center gap-1 text-gray-700 flex-grow"
        >
          <FaFileUpload />
          <span className="flex-grow">{fileName || labelName}</span>
        </label>
      </div>
      {fileError && <div className="text-red-500 text-sm">{fileError}</div>}
      {error && <div className="text-red-500 text-sm">{error}</div>}
    </div>
  );
};

export default AttachmentInput;
