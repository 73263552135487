export function formatTimestamp(isoDate) {
  const date = new Date(isoDate);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

// export const formatTimestamp = (timestamp) => {
//   const date = new Date(timestamp);
//   const day = String(date.getUTCDate()).padStart(2, "0");
//   const month = String(date.getUTCMonth() + 1).padStart(2, "0");
//   const year = date.getUTCFullYear();
//   const formattedDate = `${year}/${month}/${day}`;
//   return formattedDate;
// };

