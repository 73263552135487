import i18next from "i18next";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { useTranslation } from "react-i18next";

export default function AccountStatementTable({ data }) {
  const lang = i18next.language;
  const indexedData = data
    ? data.map((item, index) => ({ ...item, index: index + 1 }))
    : [];

  const { t } = useTranslation();

  if (!indexedData || !indexedData) {
    return <div>No ledger data available</div>;
  }

  return (
    <>
      <div className="bg-white !rounded-tl-md !rounded-tr-md">
        <div className="grid grid-cols-12 bg-secondary font-bold py-1 !rounded-tl-md !rounded-tr-md">
          <div className="col-span-1 px-1.5">{t("no")}</div>
          <div className="col-span-2">{t("date")}</div>
          <div className="col-span-3">{`${t("account")}`}</div>
          <div className="col-span-2">{`${t("sub_type")}`}</div>
          <div className="col-span-2">{`${t("debit")} ${t("amount")}`}</div>
          <div className="col-span-2">{`${t("credit")} ${t("amount")}`}</div>
          {/* <div className="col-span-4">{`${t("description")}`}</div> */}
        </div>
        {indexedData.map((asset) => (
          <div
            key={asset.id}
            className="grid grid-cols-12 items-center py-1 border-b last:border-b-0"
          >
            <div className="col-span-1 px-1.5">{asset.index}</div>
            <div className="col-span-2">{asset.transaction_date}</div>
            <div className="col-span-3">
              {asset.account_code}-
              {lang === "ar"
                ? `${asset.account_type_ar}`
                : `${asset.account_type_en}`}
            </div>

            <div className="col-span-2">
              {lang === "ar"
                ? `${asset.account_sub_type_ar}`
                : `${asset.account_sub_type_en}`}
            </div>

            <div className="col-span-2">
              {asset.transaction_total_debit}{" "}
              <span className="text-sm">{`${t("SAR")}`}</span>
            </div>

            <div className="col-span-2">
              {asset.transaction_total_credit}{" "}
              <span className="text-sm">{`${t("SAR")}`}</span>
            </div>

            {/* <div className="col-span-4">
              {asset.account_description}
            </div> */}
          </div>
        ))}
      </div>
    </>
  );
}
