import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Fa42Group, FaUpDown } from "react-icons/fa6";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
// import { ChevronRight, ChevronDown } from 'lucide-react';

const TreeNode = ({ node, level }) => {
  const [isOpen, setIsOpen] = useState(true);

  const language = i18next.language;
  const hasChildren = node.children && node.children.length > 0;

  const toggleOpen = () => setIsOpen(!isOpen);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "SAR",
    }).format(value);
  };
  const { t } = useTranslation();
  return (
    <>
      <div
        className={`grid grid-cols-12 items-center py-2 border-b px-4 ${
          level === 0 ? "font-medium" : ""
        }`}
      >
        <div
          //   className={`col-span-${7 - level} flex items-center `}
          className={`col-span-9 flex items-center `}
          style={{ paddingLeft: `${level * 20}px` }}
        >
          {hasChildren && (
            <button onClick={toggleOpen} className="mr-2">
              {isOpen ? (
                <IoIosArrowDown size={16} />
              ) : (
                <IoIosArrowForward size={16} />
              )}
            </button>
          )}
          <span>
            {language === "en" ? node?.data?.name_en : node?.data?.name_ar}
          </span>
        </div>
        <div className={`col-span-3 `}>
          {node?.data?.balance ? node?.data?.balance.toFixed(2) : "0"}{" "}
          <span>{t("SAR")}</span>
        </div>
      </div>
      {isOpen &&
        hasChildren &&
        node.children.map((child, index) => (
          <TreeNode
            key={child.key}
            node={child}
            level={level + 1}
            language={language}
          />
        ))}
    </>
  );
};

const Liabilities = ({ data, language = "en" }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="py-2 text-xl font-semibold">3 - {t("Liabilities")}</div>
      <div className="bg-white rounded-lg shadow overflow-hidden my-2">
        <div className="grid grid-cols-12 bg-gray-200 font-bold py-2 px-4">
          <div className="col-span-9">{t("name")}</div>
          <div className="col-span-3">{t("balance")}</div>
        </div>
        {data?.liabilities?.accounts?.map((account, index) => (
          <TreeNode
            key={account.key}
            node={account}
            level={0}
            language={language}
          />
        ))}
      </div>
    </>
  );
};

export default Liabilities;








// import React from "react";
// import BalanceSheetTable from "./BalanceSheetTable";
// import { Card } from "primereact/card";
// import { useTranslation } from "react-i18next";
// import i18next from "i18next";

// export default function Liabilities({ liabilities }) {
//   const { t } = useTranslation();
//   const lang = i18next.language;

//   if (!liabilities || !liabilities.accounts) {
//     return <div>No liabilities data available</div>;
//   }
//   return (
//     <div>
//       {/* <BalanceSheetTable accounts={liabilities?.accounts} /> */}
//       <div className=" py-2">3 - {t("Liabilities")}</div>
//       <div className="bg-white !rounded-tl-md !rounded-tr-md">
//         <div className="grid grid-cols-12 !bg-secondary font-bold py-1 !rounded-tl-md !rounded-tr-md">
//           <div className="col-span-1 px-1.5">{t("no")}</div>
//           <div className="col-span-6">{t("name")}</div>
//           <div className="col-span-5">{t("amount")}</div>
//         </div>

//         {liabilities.accounts.map((asset, index) => (
//           <div
//             key={asset.id}
//             className="grid grid-cols-12 items-center py-1 border-b last:border-b-0"
//           >
//             <div className="col-span-1 px-1.5">{index + 1}</div>
//             <div className="text-base col-span-6">
//               {asset.code} -
//               {lang === "ar" ? `${asset.name_ar}` : `${asset.name_en}`}
//             </div>
//             <div className="font-medium col-span-5">{asset.balance ? asset.balance.toFixed(2) : "0"} <span className="text-sm">{`${t("SAR")}`}</span></div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }
