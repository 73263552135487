import React, { useEffect, useRef, useState } from "react";
import SharedButton from "../../components/Shared/SharedButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountStatementList,
  getLedgerList,
} from "../../store/slices/ReportSlice";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";
import { getOneMonthAgo } from "../CashInflow/CashInflow";
import { Card } from "primereact/card";
import AccountStatementTable from "./AccountStatementTable";
import CustomSearchDropdown from "../../components/Shared/CustomSearchDropdown";
import { getListAccounts } from "../../store/slices/AcountsSlice";
import i18next from "i18next";
import { Dropdown } from "primereact/dropdown";

export default function AccountStatementMain() {
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [startDate, setStartDate] = useState(getOneMonthAgo(endDate));
  const [accountId, setAccountId] = useState(null);
  const { isLoading, accountStatementList } = useSelector((state) => state.reports);
  const { listAccounts } = useSelector((state) => state.acount);
  const dispatch = useDispatch();
  const balanceSheetRef = useRef(null);
  const iframeRef = useRef(null);
  const lang = i18next.language;

  useEffect(() => {
    dispatch(getListAccounts());
  }, []);

  const handleSubmit = () => {
    if (startDate && endDate && accountId) {
      const payload = {
        start_date: startDate,
        end_date: endDate,
        account_id: accountId,
        page: 1,
        per_page: 20,
      };
      dispatch(getAccountStatementList(payload));
    }
  };

  useEffect(() => {
    const payload = {
      start_date: startDate,
      end_date: endDate,
      account_id: accountId,
      page: 1,
      per_page: 20,
    };
    dispatch(getAccountStatementList(payload));
  }, []);

  const { t } = useTranslation();

  const handlePrint = () => {
    const printContents = balanceSheetRef.current.innerHTML;
    const iframe = iframeRef.current;
    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write("<html><head><title>Balance Sheet</title>");
    doc.write(
      '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css">'
    );

    doc.write("<style>");
    doc.write(`
      @media print {
        @page {
          margin: 20px 0 20px 0; /* Removes the default margins */
        }
        body {
          margin: 1cm; /* Adjust the margins to your needs */
        }
        /* Hide browser print headers and footers */
        header, footer {
          display: none;
        }
      }
    `);
    doc.write("</style>");

    doc.write("</head><body>");
    doc.write(printContents);
    doc.write("</body></html>");
    doc.close();

    iframe.onload = () => {
      iframe.contentWindow.print();
    };
  };

  //   const listAccountsvalues = listAccounts?.data
  //     ? Object.entries(listAccounts.data).map(([id, account]) => ({
  //         id: Number(account?.id),
  //         name: lang === "ar" ? account.name_ar : account.name_en,
  //       }))
  //     : [];
  const listAccountsvalues = listAccounts?.data
    ? Object.entries(listAccounts.data).map(([id, account]) => ({
        value: Number(account?.id),
        label: lang === "ar" ? account.name_ar : account.name_en,
      }))
    : [];

    // console.log("LOggsss: ", accountStatementList);

  return (
    <div>
      <div className="flex justify-between">

          <div className="flex gap-5 flex-wrap">
          <div className="">
            {/* <CustomSearchDropdown
              label={t("account")}
              className="!w-full"
              name={`account_id`}
              options={listAccountsvalues}
              placeholder={`${t("select")} ${t("account")}`}
            /> */}
            <Dropdown
              onChange={(e) => setAccountId(e.value)}
              value={accountId}
              name="account_id"
              options={listAccountsvalues}
              placeholder={`${t("select")} ${t("account")}`}
              className={`w-full p-0 border-gray-300 rounded-md hover:border-primary outline-none shadow-none`}
              pt={{
                input: { className: "!w-full !p-1.5" },
                list: { className: "!py-0" },
                filterIcon: { className: "!right-[10px]" },
                root: { className: `!w-full` },
              }}
            />

            {/* <Dropdown
              onChange={(e) => {
                console.log(e.target?.id);
              }}
              name="account_id"
              options={listAccountsvalues}
              placeholder={`${t("select")} ${t("account")}`}
              className={`w-full p-0 border-gray-300 rounded-md hover:border-primary outline-none shadow-none`}
              pt={{
                input: { className: "!w-full !p-1.5" },
                list: { className: "!py-0" },
                filterIcon: { className: "!right-[10px]" },
                root: { className: `!w-full ` },
              }}
            /> */}
          </div>
            <div className="flex items-center gap-2">
              <label className="text-sm">{t("StartDate")}</label>
              <input
                required
                type="date"
                name="start_date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
                id="dobDate"
                autoComplete="off"
                className="p-[0.33rem] border rounded-md"
              />
            </div>
            <div className="flex items-center gap-2">
              <label className="text-sm">{t("EndDate")}</label>
              <input
                required
                type="date"
                name="end_date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                id="dobDate"
                autoComplete="off"
                className="p-[0.33rem] border rounded-md"
              />
            </div>
            <div className="">
              <SharedButton
                label={t("Submit")}
                className="bg-gradient"
                onClick={handleSubmit}
                // disabled={!startDate || !endDate}
                disabled={!startDate || !endDate || !accountId}
              />
            </div>
          </div>
        


        <div className="">
          <SharedButton
            label={`${t("Print")} `}
            icon="pi pi-print"
            onClick={handlePrint}
            className="bg-gradient"
          />
        </div>
      </div>

      <div className="this_div" ref={balanceSheetRef}>
        <div className="pb-8">
          <div className="title !my-8">
            <div className="flex justify-center">
              <div className="mx-10 !mt-1">
                <div className="font-semibold text-black/70 text-xl pb-4">
                  {`${t("accountStatement")}`}
                </div>{" "}
              </div>
            </div>
          </div>

          <div className="">
            <div
              className=""
              pt={{
                content: { className: "!py-0" },
              }}
            >
              <div className="">
                <AccountStatementTable data={accountStatementList?.data} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <iframe ref={iframeRef} style={{ display: "none" }}></iframe>
      {/* <Loader showLoader={isLoading} /> */}
    </div>
  );
}
