import i18next from "i18next";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import React from "react";
import { useTranslation } from "react-i18next";

export default function LedgerTable({ data }) {
  const lang = i18next.language;
  const totalAmont = data?.total_amount;
  const tableData = data?.data;
  const indexedData = tableData
    ? tableData.map((item, index) => ({ ...item, index: index + 1 }))
    : [];
  const { t } = useTranslation();
  return (
    <>
      <div className="">
        <div className="bg-white !rounded-tl-md !rounded-tr-md">
          <div className="grid grid-cols-12 bg-secondary font-bold py-1 !rounded-tl-md !rounded-tr-md">
            <div className="col-span-1 px-1.5">{t("no")}</div>
            <div className="col-span-3">{t("account")}</div>
            <div className="col-span-2">{`${t("account")} ${t("type")}`}</div>
            <div className="col-span-2">{t("opening_balance")}</div>
            <div className="col-span-2">{t("closing_balance")}</div>
            <div className="col-span-1">{t("debit")}</div>
            <div className="col-span-1">{t("credit")}</div>
          </div>

          {/* {indexedData.map((asset) => (
          const debit = asset?.movement > 0 ? asset.movement.toFixed(2) : "0";
            const credit = asset?.movement < 0 ? (-asset.movement).toFixed(2) : "0";
            <div
              key={asset.id}
              className="grid grid-cols-12 items-center py-1 border-b last:border-b-0"
            >
              <div className="col-span-1 px-1.5">{asset.index}</div>
              <div className="col-span-3">
                {asset.account_code}-{asset.account_name}
              </div>

              <div className="col-span-2">
                {asset.account_type} <span className="text-sm">{`${t("SAR")}`}</span>
              </div>
              <div className="col-span-2">
                {asset?.opening_balance ? asset.opening_balance.toFixed(2): "0"}{" "}{`${t("SAR")}`}
              </div>
              <div className="col-span-2">
                {asset?.closing_balance ? asset.closing_balance.toFixed(2): "0"}{" "}{`${t("SAR")}`}
              </div>

              <div className="col-span-1 debit">
                {asset?.movement ? asset.movement.toFixed(2): "0"}{" "}{`${t("SAR")}`}
              </div>
              <div className="col-span-1 credit">
              {asset?.movement ? asset.movement.toFixed(2): "0"}{" "}{`${t("SAR")}`}
              </div>
            </div>
          ))} */}

          {indexedData.map((asset) => {
            const debit = asset?.movement > 0 ? asset.movement.toFixed(2) : "0";
            const credit = asset?.movement < 0 ? (-asset.movement).toFixed(2) : "0";
            return (
              <div
                key={asset.id}
                className="grid grid-cols-12 items-center py-1 border-b last:border-b-0"
              >
                <div className="col-span-1 px-1.5">{asset.index}</div>
                <div className="col-span-3">
                  {asset.account_code}-{asset.account_name}
                </div>
                <div className="col-span-2">
                  {asset.account_type}
                </div>
                <div className="col-span-2">
                  {asset?.opening_balance ? asset.opening_balance.toFixed(2) : "0"}{" "}{`${t("SAR")}`}
                </div>
                <div className="col-span-2">
                  {asset?.closing_balance ? asset.closing_balance.toFixed(2) : "0"}{" "}{`${t("SAR")}`}
                </div>
                <div className="col-span-1 debit">
                  {debit}{" "}{`${t("SAR")}`}
                </div>
                <div className="col-span-1 credit">
                  {credit}{" "}{`${t("SAR")}`}
                </div>
              </div>
            );
          })}
        </div>

        {/* <DataTable value={indexedData} tableStyle={{ minWidth: "50rem" }}>
          <Column
            field="index"
            header={t("no")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />
          <Column
            field="date"
            header={t("date")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary !rounded-tl-md",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />
          <Column
            field={(rowData) =>
              rowData.accounts
                ? lang === "ar"
                  ? rowData.accounts.name_ar
                  : rowData.accounts.name_en
                : ""
            }
            header={t("name")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary !rounded-tl-md",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />
          <Column
            field="debit"
            header={t("debit")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary !rounded-tr-md",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />
          <Column
            field="credit"
            header={t("credit")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary !rounded-tr-md",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />
          <Column
            field="balance"
            header={t("balance")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary !rounded-tr-md",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />
        </DataTable> */}
      </div>

      {/* <div className="">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 mt-6 ">
          <div className="card shadow-md px-3.5 py-3 rounded md:col-start-2 lg:col-start-3 text-center lg:text-right">
            <h2 className="font-bold text-lg mb-2 px-8 text-gray-800 !text-center">
              {t("Sub Totals")}
            </h2>
            <Divider className="!mb-3.5 !mt-0" />
            <p className="text-gray-700 text-sm mb-2 flex justify-between">
              <span className="font-semibold">{t("Total Credit")}:</span>{" "}
              <span className="text-sm">
                {totalAmont?.totalCredit || "___"} {`${t("SAR")}`}
              </span>
            </p>
            <p className="text-gray-700 text-sm flex justify-between">
              <span className="font-semibold">{t("Total Debit")}:</span>{" "}
              <span className="text-sm">
                {totalAmont?.totalDebit || "___"} {`${t("SAR")}`}
              </span>
            </p>
            <p className="text-gray-700 text-sm my-2 flex justify-between">
              <span className="font-semibold">{t("Total Balance")}:</span>{" "}
              <span className="text-sm">
                {totalAmont?.totalBalance
                  ? totalAmont.totalBalance.toFixed(2)
                  : "___"}{" "}
                {`${t("SAR")}`}
              </span>
            </p>
          </div>
        </div>
      </div> */}
    </>
  );
}
