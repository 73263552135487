import { Dialog } from "primereact/dialog";
import React from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import SharedInputText from "../components/Shared/SharedInputText";
import SharedButton from "../components/Shared/SharedButton";
import {
  CreateBusiness,
  GetBusinesses,
  GetBusinessesList,
} from "../store/slices/BusinessSlice";
import { useSelector } from "react-redux";
import Loader from "../../src/Loader";
import { useTranslation } from "react-i18next";

export default function BusinessModal({
  setCreateDialogVisible,
  createDialogVisible,
  dispatch,
}) {
  const initialValues = {
    name_en: "",
    name_ar: "",
    address: "",
    contact_info: "",
    vat_no: "",
    cr_no: "",
  };
  const { t } = useTranslation();

  const { isLoading } = useSelector((state) => state.business);
  const validationSchema = Yup.object().shape({
    name_en: Yup.string().required(t("required")),
    name_ar: Yup.string().required(t("required")),
    address: Yup.string().required(t("required")),
    contact_info: Yup.string().required(t("required")),
    cr_no: Yup.string().required(t("required")),
    vat_no: Yup.string().required(t("required")),
  });

  return (
    <>
      <Dialog
        header={t("CreateCompany")}
        visible={createDialogVisible}
        onHide={() => {
          if (!createDialogVisible) return;
          setCreateDialogVisible(false);
        }}
        pt={{
          root: { className: "w-full lg:w-10/12 mx-4 overflow-auto" },
          header: { className: "!p-2 !bg-darkBg !text-white" },
          content: { className: "!p-3" },
          closeButton: {
            className: "hover:!bg-black/20 !outline-none !shadow-none",
          },
          closeButtonIcon: { className: "!text-white" },
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            const resultAction = await dispatch(CreateBusiness(values));
            if (CreateBusiness.fulfilled.match(resultAction)) {
              dispatch(GetBusinessesList({ page: 1, per_page: 10 }));
              resetForm();
              setCreateDialogVisible(false);
            }
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <div className="grid grid-cols-4 gap-4 w-full">
                <Field
                  as={SharedInputText}
                  label={t("name_en")}
                  name="name_en"
                  placeholder={`${t("enter")} ${t("name_en")}`}
                  className="!p-1.5"
                />
                <Field
                  as={SharedInputText}
                  label={t("name_ar")}
                  name="name_ar"
                  placeholder={`${t("enter")} ${t("name_ar")}`}
                  className="!p-1.5"
                />

                <Field
                  as={SharedInputText}
                  label={t("contact_info")}
                  name="contact_info"
                  placeholder={t("contact_info_placeholder")}
                  className="!p-1.5"
                />

                <Field
                  as={SharedInputText}
                  label={t("address")}
                  name="address"
                  placeholder={`${t("enter")} ${t("address")}`}
                  // placeholder={t("address_placeholder")}
                  className="!p-1.5"
                />

                <Field
                  as={SharedInputText}
                  label={t("vat_no")}
                  name="vat_no"
                  placeholder={t("vat_no_placeholder")}
                  className="!p-1.5"
                />

                <Field
                  as={SharedInputText}
                  label={t("cr_no")}
                  name="cr_no"
                  placeholder={t("cr_no_placeholder")}
                  className="!p-1.5"
                />
              </div>

              <div className="flex justify-end gap-6 mt-6">
                <SharedButton
                  icon="pi pi-times"
                  label={t("Cancel")}
                  className="!p-1.5 !bg-red-400 hover:!bg-red-400"
                  onClick={() => {
                    setCreateDialogVisible(false);
                    formik.resetForm();
                  }}
                  type="button"
                />
                <SharedButton
                  icon="pi pi-check"
                  label={t("Submit")}
                  className="!p-1.5 bg-gradient"
                  type="submit"
                />
              </div>
            </form>
          )}
        </Formik>
      </Dialog>
      <Loader showLoader={isLoading} />
    </>
  );
}
