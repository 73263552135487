import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBalaceSheet } from "../../store/slices/ReportSlice";
import { useTranslation } from "react-i18next";
import Assets from "./Assets";
import Equity from "./Equity";
import Liabilities from "./Liabilities";
import Expenses from "./Expenses";
import Revenues from "./Revenues";
import SharedButton from "../../components/Shared/SharedButton";
import { Card } from "primereact/card";
import CustomBalanceSheetTreeTable from "./CustomBalanceSheetTreeTable";

export default function BalanceSheetPage() {
  const balanceSheetRef = useRef(null);
  const iframeRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading, balanceSheet } = useSelector((state) => state.reports);

  useEffect(() => {
    dispatch(getBalaceSheet({ page: 1, per_page: 10 }));
  }, []);

  // const handlePrint = () => {
  //   const printContents = balanceSheetRef.current.innerHTML;
  //   const iframe = iframeRef.current;
  //   const doc = iframe.contentWindow.document;
  //   doc.open();
  //   doc.write("<html><head><title>Balance Sheet</title>");
  //   doc.write('<link rel="stylesheet" href="https://cdn.tailwindcss.com">');
  //   doc.write("</head><body>");
  //   doc.write(printContents);
  //   doc.write("</body></html>");
  //   doc.close();
  //   iframe.onload = () => {
  //     iframe.contentWindow.print();
  //   };
  // };

  const handlePrint = () => {
    const printContents = balanceSheetRef.current.innerHTML;
    const iframe = iframeRef.current;
    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write("<html><head><title>Balance Sheet</title>");
    doc.write(
      '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css">'
    );

    doc.write("<style>");
    doc.write(`
      @media print {
        @page {
          margin: 20px 0 20px 0; /* Removes the default margins */
        }
        body {
          margin: 1cm; /* Adjust the margins to your needs */
        }
        /* Hide browser print headers and footers */
        header, footer {
          display: none;
        }
      }
    `);
    doc.write("</style>");

    doc.write("</head><body>");
    doc.write(printContents);
    doc.write("</body></html>");
    doc.close();

    iframe.onload = () => {
      iframe.contentWindow.print();
    };
  };

  return (
    <>
      <div className="btn flex justify-end">
        <SharedButton
          label={`${t("Print")}`}
          icon="pi pi-print"
          onClick={handlePrint}
          className="bg-gradient"
        />
      </div>

      <div className="this_div" ref={balanceSheetRef}>
        <div className="!pb-8">
          <div className=" one">
            <div
              className=""
              pt={{
                content: { className: "!py-0" },
                body: { className: "!p-0" },
                root: { className: "!shadow-none" },
              }}
            >
              <div className="">
                {/* <Assets assets={balanceSheet?.assets} /> */}
                <CustomBalanceSheetTreeTable data={balanceSheet} />
              </div>

              <div className="grid grid-cols-5 gap-4">
                <div className="col-span-5 flex justify-end">
                  <div className="mx-16 !mt-2 flex gap-2">
                    <span className="font-semibold text-black/70 text-lg">
                      {`${t("assets")} ${t("Total")}`}
                    </span>
                    <span className="font-semibold text-black/60 text-lg">
                      {/* : {balanceSheet?.totals?.total_assets} {`${t("SAR")}`} */}
                      {balanceSheet?.totals?.total_assets
                        ? balanceSheet?.totals?.total_assets.toFixed(2)
                        : "0"}{" "}
                      <span>{t("SAR")}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" two">
            <div
              className=""
              pt={{
                content: { className: "!py-0" },
                body: { className: "!p-0" },
                root: { className: "!shadow-none" },
              }}
            >
              <div className="">
                <Equity data={balanceSheet} />
                {/* <Equity equity={balanceSheet?.equity} /> */}
                {/* <CustomBalanceSheetTreeTable data={balanceSheet}  /> */}
              </div>

              <div className="grid grid-cols-5 gap-4">
                <div className="col-span-5 flex justify-end">
                  <div className="mx-16 !mt-2 flex gap-2">
                    <span className="font-semibold text-black/70 text-lg">
                      {`${t("Equity")} ${t("Total")}`}
                    </span>
                    <span className="font-semibold text-black/60 text-lg">
                      {/* : {balanceSheet?.totals?.total_equity}
                      {`${t("SAR")}`} */}
                      {balanceSheet?.totals?.total_equity
                        ? balanceSheet?.totals?.total_equity.toFixed(2)
                        : "0"}{" "}
                      <span>{t("SAR")}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" three">
            <div
              className=""
              pt={{
                content: { className: "!py-0" },
                body: { className: "!p-0" },
                root: { className: "!shadow-none" },
              }}
            >
              <div className="">
                <Liabilities data={balanceSheet} />
                {/* <Liabilities liabilities={balanceSheet?.liabilities} /> */}
              </div>

              <div className="grid grid-cols-5 gap-4">
                <div className="col-span-5 flex justify-end">
                  <div className="mx-16 !mt-2 flex gap-2">
                    <span className="font-semibold text-black/70 text-lg">
                      {`${t("Liabilities")} ${t("Total")}`}
                    </span>
                    <span className="font-semibold text-black/60 text-lg">
                      {/* : {balanceSheet?.totals?.total_liabilities}{" "}
                      {`${t("SAR")}`} */}
                      {balanceSheet?.totals?.total_liabilities
                        ? balanceSheet?.totals?.total_liabilities.toFixed(2)
                        : "0"}{" "}
                      <span>{t("SAR")}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <iframe ref={iframeRef} style={{ display: "none" }}></iframe>
    </>
  );
}
