import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import validSupergitHIS from "../../api/validSupergitHIS";
import toast from "react-hot-toast";

export const getItems = createAsyncThunk(
  "getItem",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("items", data);
      return response.data.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
export const getItemsList = createAsyncThunk(
  "getItemList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("listItems", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const CreateItems = createAsyncThunk(
  "createItem",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("item", data);

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
export const getPriceListByID = createAsyncThunk(
  "getPriceListByID",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("items", payload);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);





export const getSearchedItem = createAsyncThunk(
  "getSearchedItem",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("searchItem", payload);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);




const initialState = {
  items: {},
  isLoading: false,
  itemsList: [],
};

const ItemSlice = createSlice({
  name: "items",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(CreateItems.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(CreateItems.fulfilled, (state, action) => {
      state.isLoading = false;
      toast.success(action.payload.data || "Succesfull");
    });
    builder.addCase(CreateItems.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.error.message);
    });
    builder.addCase(getItems.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getItems.fulfilled, (state, action) => {
      state.items = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getItems.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.error.message);
    });
    builder.addCase(getItemsList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getItemsList.fulfilled, (state, action) => {
      state.itemsList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getItemsList.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.error.message);
    });
    builder.addCase(getPriceListByID.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPriceListByID.fulfilled, (state, action) => {
      state.items = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getPriceListByID.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.error.message);
    })




    .addCase(getSearchedItem.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getSearchedItem.fulfilled, (state, action) => {
      state.isLoading = false;
      state.itemsList = action.payload;
    })
    .addCase(getSearchedItem.rejected, (state) => {
      state.isLoading = false;
    });





  },
});

export default ItemSlice.reducer;
