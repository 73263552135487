import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import validSupergitHIS from "../../api/validSupergitHIS";
import toast from "react-hot-toast";

export const createInvoice = createAsyncThunk(
  "createInvoice",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("patientInvoice", data);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const printInvoice = createAsyncThunk(
  "printInvoice",
  async (id, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(`print_invoice/${id}`);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const printCompanyInvoice = createAsyncThunk(
  "printCompanyInvoice",
  async (id, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(
        `print_company_invoice/${id}`
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const createCompanyInvoice = createAsyncThunk(
  "createCompanyInvoice",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("patientInvoice", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const createPatientInvoice = createAsyncThunk(
  "createPatientInvoice",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("patientInvoice", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const getInvoicesList = createAsyncThunk(
  "getInvoicesList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("listInvoices", payload);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const getCompanyInvoicesList = createAsyncThunk(
  "getCompanyInvoicesList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(
        "listInsuranceInvoice",
        payload
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);

export const getSearchedInvoice = createAsyncThunk(
  "getSearchedInvoice",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("searchInvoices", payload);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);
export const getSearchedInsuranceInvoice = createAsyncThunk(
  "getSearchedInsuranceInvoice",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post(
        "searchCompanyInvoices",
        payload
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);



export const CreateNewInvoicePayment = createAsyncThunk(
  "CreateNewInvoicePayment",
  async (data, { rejectWithValue }) => {
    try {
      const response = await validSupergitHIS.post("recordInvoicePayment", data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      return rejectWithValue(
        error?.response?.data?.error || "something went wrong"
      );
    }
  }
);



const initialState = {
  isLoading: false,
  invoiceCode: null,
  companyInvoiceCode: null,
  invoicesList: [],
  companyInvoicesList: [],
  searchedInvoice: null,
};

const InvoicesSlice = createSlice({
  name: "invoices",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(createInvoice.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(printInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(printInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invoiceCode = action.payload;
      })
      .addCase(printInvoice.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(printCompanyInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(printCompanyInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.companyInvoiceCode = action.payload;
      })
      .addCase(printCompanyInvoice.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createCompanyInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCompanyInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(createCompanyInvoice.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getInvoicesList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInvoicesList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invoicesList = action.payload;
      })
      .addCase(getInvoicesList.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getCompanyInvoicesList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCompanyInvoicesList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.companyInvoicesList = action.payload;
      })
      .addCase(getCompanyInvoicesList.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getSearchedInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSearchedInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invoicesList = action.payload;
      })
      .addCase(getSearchedInvoice.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getSearchedInsuranceInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSearchedInsuranceInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.companyInvoicesList = action.payload;
      })
      .addCase(getSearchedInsuranceInvoice.rejected, (state) => {
        state.isLoading = false;
      })


      .addCase(CreateNewInvoicePayment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CreateNewInvoicePayment.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action?.payload?.message);
      })
      .addCase(CreateNewInvoicePayment.rejected, (state) => {
        state.isLoading = false;
      })


  },
});

export default InvoicesSlice.reducer;

// toast.success(action.payload.data || "Succesfull");
