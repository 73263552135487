import React from "react";
import { useTranslation } from "react-i18next";

export default function BankFlowCard({ bankFlow }) {
  // console.log(bankFlow);
  const { t } = useTranslation();
  return (
    <div>
      <div className="bg-white rounded-lg shadow-md  w-full my-5">
        <div className="text-black rounded-t-lg bg-[#c5c5c7]">
          <h2 className="text-lg font-semibold p-1 mb-4">
            {t("BankTransferFlow")}
          </h2>
        </div>
        <div className="space-y-3 p-3">
          <div className="flex justify-between">
            <span className="font-medium">{t("CashInflow")}:</span>
            <span>{bankFlow?.bank_transfer_inflows} {t("SAR")}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium">{t("CashOutflow")}:</span>
            <span>{bankFlow?.bank_transfer_outflows} {t("SAR")}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium">{t("NetCashFlow")}:</span>
            <span>{bankFlow?.net_bank_transfer_flow} {t("SAR")}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
