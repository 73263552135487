import React, { useEffect, useState } from "react";
import SharedTooltip from "../../components/Shared/SharedTooltip";
import SharedButton from "../../components/Shared/SharedButton";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";
import { getDeductionList } from "../../store/slices/HrSlice";
import DeductionTable from "./DeductionTable";
import CreateDeduction from "./CreateDeduction";

export default function DeductionMain() {
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDeductionList({ page: 1, per_page: 10 }));
  }, []);

  const { deductionList } = useSelector((state) => state.hr);
  const { isLoading } = useSelector((state) => state.hr);
  const { t } = useTranslation();
  return (
    <>
      <div>
        <div className="flex justify-between flex-col gap-3 sm:gap-0 sm:flex-row mb-3">
          <SharedTooltip
            content= {t("Keep_track_of_your_employees_deductions")}
            position="left"
            id="Items"
          />
          <span
            data-tooltip-id="Items"
            className="font-semibold text-black/70 text-xl mt-2 "
          >
            {t("DEDUCTIONS")}
            <span className="italic text-lg ml-1">
              (
                 {deductionList?.total_record}
              <span className="text-xs font-bold italic"> {t("Entries")}</span>)
            </span>
          </span>

          <SharedButton
            label={t("CreateDeduction")}
            icon="pi pi-plus"
            onClick={() => setCreateDialogVisible(true)}
            className="bg-gradient"
          />
        </div>
        <DeductionTable
         data={deductionList} 
        />
        <CreateDeduction
          setCreateDialogVisible={setCreateDialogVisible}
          createDialogVisible={createDialogVisible}
        />
      </div>
      <Loader showLoader={isLoading} />
    </>
  );
}
