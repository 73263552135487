import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
export default function SharedTooltip({ id, content, position }) {
  return (
    <ReactTooltip
      id={id}
      place={position ? position : "bottom"}
      content={content.toUpperCase()}
      style={{ padding: "3px 6px", backgroundColor: "#373d45" }}
    />
  );
}
