import { Button } from "primereact/button";
import React, { useEffect, useRef } from "react";
import { FaArrowLeft, FaPrint } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";

export default function ShowCompanyInvoice() {
  const { companyInvoiceCode } = useSelector((state) => state.invoice);
  const invoiceRef = useRef(null);
  const navigate = useNavigate();
  const iframeRef = useRef(null);

  useEffect(() => {
    if (invoiceRef.current && companyInvoiceCode) {
      invoiceRef.current.innerHTML = companyInvoiceCode;
    }
  }, [companyInvoiceCode]);
  const { isLoading } = useSelector((state) => state.invoice);

  const handlePrint = () => {
    const printContents = invoiceRef.current.innerHTML;
    const iframe = iframeRef.current;
    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write("<html><head><title>Invoice</title>");
    doc.write('<link rel="stylesheet" href="https://cdn.tailwindcss.com">');
    doc.write("</head><body>");
    doc.write(printContents);
    doc.write("</body></html>");
    doc.close();
    iframe.onload = () => {
      iframe.contentWindow.print();
    };
  };
  const { t } = useTranslation();
  return (
    <>
      <div>
        <div className="flex gap-3">
          <Button
            variant="contained"
            onClick={() => navigate("/manager/insuranceInvoice")}
            style={{
              backgroundImage:
                "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
            }}
            className="bg-orange-500 border-orange-500 shadow-orange-500 hover:border-orange-500"
            startIcon={<FaArrowLeft />}
            size="small"
          >
            {t("Back")}
          </Button>
          <Button
            variant="contained"
            onClick={handlePrint}
            style={{
              backgroundImage:
                "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
            }}
            startIcon={<FaPrint />}
            className="bg-orange-500 border-orange-500 shadow-orange-500 hover:border-orange-500 focus:border-orange-500"
            size="small"
          >
            {t("PrintInvoice")}
          </Button>
        </div>

        <div id="print-invoice" ref={invoiceRef}></div>
        <iframe ref={iframeRef} style={{ display: "none" }}></iframe>
      </div>
      <Loader showLoader={isLoading} />
    </>
  );
}
