import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SharedButton from "../../components/Shared/SharedButton";
import CreateAccount from "./CreateAccount";
import SharedTooltip from "../../components/Shared/SharedTooltip";
import AccountsTable from "./AccountsTable";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";
import { getAccounts } from "../../store/slices/AcountsSlice";
import SearchInput from "../../components/Shared/Search";

export default function AccountsMain() {
  const dispatch = useDispatch();
  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const { t } = useTranslation();

  const { accounts, isLoading } = useSelector((state) => state.acount);

  useEffect(() => {
    dispatch(getAccounts());
  }, [dispatch]);

  useEffect(() => {
    const filterNode = (node, query) => {
      const lowerQuery = query.toLowerCase();
      const matchNameEn = node.data.name_en?.toLowerCase().includes(lowerQuery);
      const matchNameAr = node.data.name_ar?.toLowerCase().includes(lowerQuery);
      const matchDigitsOrNameEn = node.data.name_en?.split("-").some(part => part.toLowerCase().includes(lowerQuery));
      const matchDigitsOrNameAr = node.data.name_ar?.split("-").some(part => part.toLowerCase().includes(lowerQuery));
  
      return matchNameEn || matchNameAr || matchDigitsOrNameEn || matchDigitsOrNameAr;
    };
  
    const filterTree = (nodes, query) => {
      return nodes.flatMap((node) => {
        if (filterNode(node, query)) {
          return [node];
        }
  
        if (node.children) {
          const filteredChildren = filterTree(node.children, query);
          if (filteredChildren.length > 0) {
            return filteredChildren;
          }
        }
          return [];
      });
    };
  
    if (!searchQuery) {
      setFilteredAccounts(accounts || []);
    } else {
      const filtered = filterTree(accounts, searchQuery);
      setFilteredAccounts(filtered);
    }
  }, [searchQuery, accounts]);
  

  return (
    <div>
      <div className="flex justify-between items-center flex-col gap-3 sm:gap-0 sm:flex-row mb-3">
        <SharedTooltip
          content={t("Keep_track_of_your_accounts")}
          position="left"
          id="Items"
        />
        <span
          data-tooltip-id="Items"
          className="font-semibold text-black/70 text-xl mt-2"
        >
          {t("ACCOUNTS")}
          <span className="italic text-lg ml-1">
            ({accounts?.length || 0}
            <span className="text-xs font-bold italic"> {t("Entries")}</span>)
          </span>
        </span>
        <div className="flex items-center gap-4">
          <SearchInput
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            placeholder={t("Search_by_Code_or_name")}
          />
          <SharedButton
            label={t("CreateAccount")}
            icon="pi pi-plus"
            onClick={() => setCreateDialogVisible(true)}
            className="bg-gradient"
          />
        </div>
      </div>

      <AccountsTable data={filteredAccounts} />

      <CreateAccount
        createDialogVisible={createDialogVisible}
        setCreateDialogVisible={setCreateDialogVisible}
        isLoading={isLoading}
      />
      {isLoading && <Loader showLoader={isLoading} />}
    </div>
  );
}
