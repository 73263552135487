import React, { useState } from "react";
import SharedTooltip from "../../components/Shared/SharedTooltip";
import {
  MdOutlineDelete,
  MdOutlineEdit,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import Loader from "../../Loader";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "../../components/Shared/CustomPagination";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "../../components/Shared/SharedMethods";
import { getPurchasedEntryList } from "../../store/slices/PurchaseSlice";
import { Dialog } from "primereact/dialog";
import i18next from "i18next";
import ReceivePayment from "./ReceivePatment";

export default function PurchasedEntryTable({
  data,
  selectedTransactionEntries,
  setSelectedTransactionEntries,
}) {
  const datebody = (rowData) => {
    return formatTimestamp(rowData.purchase_date);
  };

  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  // const [selectedTransactionEntries, setSelectedTransactionEntries] = useState([]);
  const [receivePaymentDialogVisible, setReceivePaymentDialogVisible] =
    useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);

  const { isLoading } = useSelector((state) => state.purchase);
  const lang = i18next.language;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onPageChange = (newPage, newRowsPerPage) => {
    dispatch(
      getPurchasedEntryList({
        page: newPage,
        per_page: newRowsPerPage,
      })
    );
  };

  const templateBody = (rowData) => (
    <div className="flex gap-1">
      <SharedTooltip content="view" id="my-tooltip-1" />
      <SharedTooltip id="my-tooltip-2" content="edit" />
      <SharedTooltip id="my-tooltip-3" content="delete" />
      <SharedTooltip id="my-tooltip-4" content="Record Payment" />
      <MdOutlineRemoveRedEye
        size={25}
        data-tooltip-id="my-tooltip-1"
        className="!p-1 !text-black hover:cursor-pointer"
        onClick={() => {
          setSelectedTransactionEntries(rowData.purchase_detail);
          setCreateDialogVisible(true);
        }}
      />

      {/* <LiaFileInvoiceDollarSolid
        size={25}
        data-tooltip-id="my-tooltip-4"
        className="!p-1 !text-black hover:cursor-pointer"
        onClick={() => {
          setSelectedEntry(rowData);
          setReceivePaymentDialogVisible(true);
        }}
      /> */}
      {rowData.status !== "paid" && (
        <LiaFileInvoiceDollarSolid
          size={25}
          data-tooltip-id="my-tooltip-4"
          className="!p-1 !text-black hover:cursor-pointer"
          onClick={() => {
            setSelectedEntry(rowData);
            setReceivePaymentDialogVisible(true);
          }}
        />
      )}

      {/* <MdOutlineEdit
        size={25}
        data-tooltip-id="my-tooltip-2"
        className="!p-1 !text-black hover:cursor-pointer"
      />
      <MdOutlineDelete
        size={25}
        data-tooltip-id="my-tooltip-3"
        className="!p-1 !text-black hover:cursor-pointer"
      /> */}
    </div>
  );
  const sequenceBodyTemplate = (rowData, options) => {
    return options.rowIndex + 1 + (data?.page - 1) * data?.per_page;
  };

  const statusBody = (data) => {
    if (data?.status == "paid") {
      return (
        <h1 className="bg-green-500 !w-[50px] p-1 text-center text-white rounded-md">
          {t("Paid")}
        </h1>
      );
    } else {
      return (
        <h1 className="bg-red-500 p-1 !w-[50px] text-center text-white rounded-md">
          {t("Unpaid")}
        </h1>
      );
    }
  };

  const balanceBody = (data) => {
    return (
      <>
        {data?.total_amount} {t("SAR")}
      </>
    );
  };

  return (
    <>
      <DataTable
        value={data?.data}
        tableStyle={{ minWidth: "50rem" }}
        // selection={selectedTransactionEntries}
        // onSelectionChange={(e) => setSelectedTransactionEntries(e.value)}
        // dataKey="id"
        // selectionMode="checkbox"
        // stripedRows
      >
        {/* <Column
          selectionMode="multiple"
          headerStyle={{ width: "3rem" }}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] !text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        /> */}
        <Column
          body={sequenceBodyTemplate}
          header={t("no")}
          style={{ width: "3rem" }}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary !rounded-tl-md",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="purchase_date"
          header={t("purchase_date")}
          body={datebody}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />
      
        <Column
          field="entry_number"
          header={t("entry_number")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="vendor.name"
          header={t("name")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="total_amount"
          body={balanceBody}
          header={t("total_amount")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="notes"
          header={t("description")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          field="status"
          header={t("status")}
          body={statusBody}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />

        <Column
          body={templateBody}
          header={t("actions")}
          pt={{
            headerCell: {
              className: "!p-[0.3rem] !bg-secondary !rounded-tr-md",
            },
            bodyCell: {
              className:
                "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
            },
          }}
        />
      </DataTable>

      <Loader showLoader={isLoading} />

      {/* Detail Dialog */}
      <Dialog
        header={t("PurchaseDetail")}
        visible={createDialogVisible}
        onHide={() => {
          if (!createDialogVisible) return;
          setCreateDialogVisible(false);
        }}
        pt={{
          root: { className: "w-full lg:w-10/12 mx-4 overflow-auto" },
          header: { className: "!p-2 !bg-darkBg !text-white" },
          content: { className: "!p-3" },
          closeButton: {
            className: "hover:!bg-black/20 !outline-none !shadow-none",
          },
          closeButtonIcon: { className: "!text-white" },
        }}
      >
        <DataTable
          value={selectedTransactionEntries}
          tableStyle={{ minWidth: "50rem" }}
          paginator={false}
        >
          <Column
            field={lang === "ar" ? "item_name_ar" : "item_name_en"}
            header={t("item_name")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />
          <Column
            field="item_price"
            header={t("item_price")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />
          <Column
            field="quantity"
            header={t("quantity")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />
          <Column
            field="subtotal"
            header={t("subtotal")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />
          <Column
            field="vat_percent"
            header={t("vat_percent")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />
          <Column
            field="vat_amount"
            header={t("vat_amount")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />

          <Column
            field="total"
            header={t("total")}
            pt={{
              headerCell: {
                className: "!p-[0.3rem] !bg-secondary",
              },
              bodyCell: {
                className:
                  "!p-[0.25rem] text-[0.875rem] leading-[1.25rem] border-b-2 !border-b-secondary",
              },
            }}
          />
        </DataTable>
      </Dialog>
      {data?.data?.length > 0 && (
        <CustomPagination
          currentPage={data?.page}
          rowsPerPage={data?.per_page}
          totalRecords={data?.total_pages}
          totalRecord={data?.total_record}
          onPageChange={onPageChange}
        />
      )}

      <ReceivePayment
        open={receivePaymentDialogVisible}
        setOpen={setReceivePaymentDialogVisible}
        selectedEntries={selectedEntry ? [selectedEntry] : []}
      />
    </>
  );
}
