import { Formik } from "formik";
import React, { useEffect } from "react";
import * as yup from "yup";
import { Dialog } from "primereact/dialog";
import SharedDropdown from "../../components/Shared/SharedDropdown";
import SharedButton from "../../components/Shared/SharedButton";
import SharedInputText from "../../components/Shared/SharedInputText";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import CustomSearchDropdown from "../../components/Shared/CustomSearchDropdown";
import {
  getExpenseAccounts,
  getListAccounts,
} from "../../store/slices/AcountsSlice";
import {
  CreateNewInvoicePayment,
  getInvoicesList,
} from "../../store/slices/InvoicesSlice";

export default function ReceivePaymentInvoice({
  selectedEntries,
  open,
  setOpen,
}) {
  function convertData(data) {
    return data?.length ? data[0].id : "";
  }
  function convertDataa(data) {
    return data?.length ? data[0].patient_share : "";
  }

  const initialValues = {
    invoice_id: convertData(selectedEntries),
    amount: convertDataa(selectedEntries),
    account_code: "",
    date: "",
    payment_method: "",
  };

  const dispatch = useDispatch();
  const lang = i18next.language;
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    date: yup.string().required(t("required")),
    payment_method: yup.string().required(t("required")),
    // account_code: yup.number().required(t("required")),
  });

  const paymentOption = [
    { value: "bank", label: "Bank" },
    { value: "cash", label: "Cash" },
    { value: "mada", label: "MADA" },
  ];

  function handleSubmit(values, { resetForm }) {
    console.log("Vva: ", values);
    
    dispatch(CreateNewInvoicePayment(values)).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        setOpen(false);
        resetForm();
        dispatch(getInvoicesList({ page: 1, per_page: 10 }));
      }
    });
  }
  useEffect(() => {
    dispatch(getListAccounts());
  }, []);

  const { isLoading } = useSelector((state) => state.invoice);
  const { user } = useSelector((state) => state.auth);
  const { listAccounts, expenseAccounts } = useSelector(
    (state) => state.acount
  );

  const listAccountsvalues = listAccounts?.data
    ? Object.entries(listAccounts.data).map(([id, account]) => ({
        id: Number(account?.code),
        name: lang === "ar" ? account.name_ar : account.name_en,
      }))
    : [];

  return (
    <>
      <Dialog
        header={t("RecordPayment")}
        visible={open}
        onHide={() => {
          if (!open) return;
          setOpen(false);
        }}
        pt={{
          root: { className: "w-full lg:w-10/12 mx-4 overflow-auto" },
          header: { className: "!p-2 !bg-darkBg !text-white" },
          content: { className: "!p-3" },
          closeButton: {
            className: "hover:!bg-black/20 !outline-none !shadow-none",
          },
          closeButtonIcon: { className: "!text-white" },
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <>
              <div className="grid grid-cols-4 gap-4">
                <SharedInputText
                  label={t("date")}
                  name="date"
                  placeholder={`${t("enter")} ${t("date")}`}
                  className="!p-1.5"
                  type={"date"}
                />
                <SharedDropdown
                  label={t("paymentMethod")}
                  placeholder={`${t("select")} ${t("paymentMethod")}`}
                  name="payment_method"
                  options={paymentOption}
                />
                {/* <CustomSearchDropdown
                  label={t("account")}
                  className="!w-full"
                  name={"account_code"}
                  options={listAccountsvalues}
                  placeholder={`${t("select")} ${t("account")}`}
                /> */}
                <CustomSearchDropdown
                  label={t("account")}
                  className="!w-full"
                  name={"account_code"}
                  options={listAccountsvalues}
                  placeholder={`${t("select")} ${t("account")}`}
                />

                {/* {console.log(props?.errors)} */}
              </div>
              <div className="flex justify-end gap-6 mt-6">
                <SharedButton
                  icon="pi pi-times"
                  label={t("Cancel")}
                  className="!p-1.5 !bg-red-400 hover:!bg-red-400"
                  onClick={() => {
                    setOpen(false);
                    props.resetForm();
                  }}
                />
                <SharedButton
                  type="submit"
                  icon="pi pi-check"
                  label={t("Submit")}
                  className="!p-1.5 bg-gradient"
                  onClick={props.handleSubmit}
                />
              </div>
            </>
          )}
        </Formik>
      </Dialog>

      <Loader showLoader={isLoading} />
    </>
  );
}
