import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SharedButton from "../../components/Shared/SharedButton";
import SharedTooltip from "../../components/Shared/SharedTooltip";
import { getUsers } from "../../store/slices/UsersSlice";
import UsersTable from "./UsersTable";
import Loader from "../../Loader";

export default function UsersMain() {
  const dispatch = useDispatch();
  const [createDialogVisible, setCreateDialogVisible] = useState(false);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const { users, isLoading } = useSelector((state) => state.user);

  return (
    <div className="">
      <div className="flex justify-between mb-3">
        <SharedTooltip
          content="Keep track of your users"
          position="left"
          id="users"
        />
        <span
          data-tooltip-id="users"
          className="font-semibold text-black/70 text-xl mt-2 "
        >
          USERS
          <span className="italic text-lg ml-1">
            ({users?.length || 0}
            <span className="text-xs font-bold italic"> Entries</span>)
          </span>
        </span>
      </div>

      <UsersTable data={users} />
      <Loader showLoader={isLoading} />
    </div>
  );
}
