import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { Field, Formik } from "formik";
import * as yup from "yup";
import SharedInputText from "../../components/Shared/SharedInputText";
import SharedButton from "../../components/Shared/SharedButton";
import { useDispatch, useSelector } from "react-redux";
import {
  createInvoice,
  getInvoicesList,
} from "../../store/slices/InvoicesSlice";
import SharedDropdown from "../../components/Shared/SharedDropdown";
import CustomSearchDropdown from "../../components/Shared/CustomSearchDropdown";
import { getItems, getPriceListByID } from "../../store/slices/ItemSlice";
import { RiDeleteBin5Line } from "react-icons/ri";
import { getCustomers } from "../../store/slices/CustomerSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCurrentDateTime } from "../Transactions/CreateTransaction";
import i18next from "i18next";

export default function CreateInvoicePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lang = i18next.language;

  useEffect(() => {
    dispatch(getPriceListByID({ payer_id: "0" }));
    dispatch(getCustomers());
  }, []);

  const { items } = useSelector((state) => state.item);
  const { user } = useSelector((state) => state.auth);
  const { customers } = useSelector((state) => state.customers);
  const [validate, setValidate] = useState("");

  const typeOptions = [
    { value: "fixed", label: "Fixed" },
    { value: "percent", label: "Percent" },
  ];
  const paid_values = [
    { value: true, label: "Paid" },
    { value: false, label: "Unpaid" },
  ];
  const payment_method_values = [
    { value: "cash", label: lang === "ar" ? "نقدي" : "Cash" },
    { value: "bank", label: lang === "ar" ? "بنك" : "Bank" },
    { value: "mada", label: lang === "ar" ? "مدى" : "MADA" },

    // { value: "cash", label: "Cash" },
    // { value: "bank", label: "Bank" },
    // { value: "mada", label: "MADA" },
  ];
  const vat_values = [
    { value: 15, label: lang === "ar" ? "نعم" : "Yes" },
    { value: 0, label: lang === "ar" ? "لا" : "No" },
  ];
  function convertData(inputData) {
    return Object.values(inputData).map((item) => ({
      id: item?.ID,
      name: lang === "ar" ? item.name_ar : item.name_en,
      amount: item?.price,
    }));
  }
  const ItemsOptions = convertData(items?.data || []);
  function customer(inputData) {
    return Object.values(inputData).map((item) => ({
      id: item.id,
      name: item.name,
      amount: item.name,
    }));
  }

  const invoice_type_values = [
    { value: "sales", label: "Sales" },
    { value: "credit", label: "Credit" },
  ];

  const initialValues = {
    date: getCurrentDateTime(),
    // amount: 0,
    description: "",
    paid: false,
    payment_method: "",
    customer_id: "",
    branch_id: user?.branch_id,
    business_id: user?.business_id,
    invoice_type: "sales",
    items: [],
  };
  const validationSchema = yup.object().shape({
    date: yup.string().required(t("required")),
    invoice_type: yup.string().required(t("required")),
    // amount: yup
    //   .number()
    //   .required("Amount is required")
    //   .min(0, "Amount must be non-negative"),
    description: yup.string().required(t("required")),
    // paid: yup.boolean().required(t("required")),
    payment_method: yup.string().required(t("required")),
    customer_id: yup.number().required(t("required")),
    items: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number().required(t("required")),
          //   patientShare: yup
          //     .number()
          //     .min(0, "Patient share must be non-negative")
          //     .required(t("required")),
          // quantity: yup
          //   .number()
          //   .integer()
          //   .min(1, "Quantity must be at least 1")
          //   .required(t("required")),

          // discount_type: yup
          //   .string()
          //   .oneOf(["fixed", "percent"], "Invalid discount type")
          //   .required(t("required")),
          discount_amount: yup
            .number()
            .min(0, `${t("discountAmount ")}${t(" mustBePositive")}`)
            .required(t("required")),
          // tax_type: yup
          //   .string()
          //   .oneOf(["fixed", "percent"], "Invalid tax type")
          //   .required(t("required")),
          tax_amount: yup
            .number()
            .min(0, `${t("taxAmount")}${t(" mustBePositive")}`)
            .required(t("required")),
        })
      )
      .min(1, t("required")),
  });
  const getLabel = (item) => {
    const object = ItemsOptions?.find((itm) => Number(itm.id) === item.id);
    return object ? object.name : "";
  };
  const getAmount = (itemId) => {
    const item = items?.data?.find((item) => item.ID === itemId);
    return item ? item?.price : 0;
  };

  const getTotalAmount = (formikProps) => {
    return formikProps?.values?.items.reduce(
      (acc, transaction) =>
        acc + Number(transaction?.quantity) * Number(transaction?.amount),
      0
    );
  };
  const GetTotalDiscount = (formikProps) => {
    const value = formikProps?.values?.items.reduce(
      (acc, transaction) => acc + transaction?.discount_amount,
      0
    );

    return formikProps?.values?.AppoinmentType === "Cash"
      ? getTotalAmount(formikProps) - value
      : getTotalAmount(formikProps) - 100 - value;
  };
  const GetPatientShare = (formikProps, value) => {
    const total = formikProps?.values?.items.reduce(
      (acc, transaction) => acc + transaction?.patientShare,
      0
    );
    const discount = formikProps?.values?.items.reduce(
      (acc, transaction) => acc + transaction?.discount_amount,
      0
    );
    if (value === "ps") {
      return total;
    } else if (value === "ins")
      return getTotalAmount(formikProps) - total - discount;
  };
  const GetNetTotal = (formikProps) => {
    return (
      GetTotalDiscount(formikProps) + (GetTotalDiscount(formikProps) * 15) / 100
    );
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (data, { resetForm }) => {
          const resultAction = await dispatch(createInvoice(data));
          if (createInvoice.fulfilled.match(resultAction)) {
            resetForm();
            navigate("/manager/invoices");
            // dispatch(getInvoicesList({ page: 1, per_page: 10 }));
          }
        }}
        validateOnChange={true}
        validateOnBlur={true}
        validate={(values) => {
          validationSchema
            .validate(values, { abortEarly: false })
            .then(() => console.log("Validation passed"))
            .catch((err) => console.log("Validation errors:", err.errors));
        }}
      >
        {(props) => (
          <>
            <div className="flex rounded-md bg-gray-100 p-6 w-full gap-4">
              <div
                className={` ${
                  props.values.items.length > 0 ? "w-[70%]" : "w-full"
                }`}
              >
                <h2 className="text-3xl font-bold mb-6 text-gray-800">
                  {`${t("create")} ${t("salesinvoice")}`}
                  {/* {console.log(props?.values)} */}
                </h2>

                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 w-full mb-3">
                  <SharedInputText
                    type={"datetime-local"}
                    label={t("date")}
                    name={"date"}
                    placeholder="Enter Date"
                    className="!p-1.5"
                  />
                  {/* <SharedInputText
                type={"number"}
                label={"Amount"}
                name={"amount"}
                placeholder="Enter Amount"
                className="!p-1.5"
              /> */}
                  <CustomSearchDropdown
                    name="customer_id"
                    placeholder={`${t("select")} ${t("customers")}`}
                    options={customer(customers || [])}
                    label={t("customers")}
                    className={"!w-full"}
                  />

                  <SharedDropdown
                    label={t("payment_method")}
                    name={"payment_method"}
                    options={payment_method_values}
                  />

                  {/* <SharedDropdown
                    label={t("paid")}
                    name={"paid"}
                    options={paid_values}
                  /> */}
                  {/* <SharedDropdown
                label={"Invoice Type"}
                name={"invoice_type"}
                options={invoice_type_values}
              /> */}

                  <SharedInputText
                    label={t("description")}
                    name={"description"}
                    placeholder={`${t("enter")} ${t("description")}`}
                    className="!p-1.5"
                  />
                </div>

                <div className="max-h-[270px] overflow-y-auto scrollbar">
                  {props?.values?.items?.map((item, index) => (
                    <div
                      key={index}
                      className="grid grid-cols-1 gap-2 mb-4 border-t border-b py-4"
                    >
                      <div className="flex gap-4">
                        <div className="flex">
                          <span className="font-semibold">{t("name")}: </span>
                          <div className="px-2">{getLabel(item)}</div>
                        </div>
                        <div className="flex">
                          <span className="font-semibold">
                            {t("ItemAmount")}:{" "}
                          </span>
                          <div className="px-2">{getAmount(item.id)}</div>
                        </div>
                      </div>

                      <div className="grid grid-cols-5 gap-4">
                        {/* <Field
                          name={`items[${index}].patientShare`}
                          as={SharedInputText}
                          label={t("patientShare")}
                          type="number"
                          placeholder={`${t("enter")}${t("patientShare")}`}
                          className="!p-1.5"
                          value={item.patientShare}
                          onChange={(e) => {
                            const newItems = [...props.values.items];
                            newItems[index].patientShare = Number(
                              e.target.value
                            );
                            props.setFieldValue("items", newItems);
                          }}
                        /> */}
                        <Field
                          name={`items[${index}].quantity`}
                          as={SharedInputText}
                          label={t(`quantity`)}
                          type="number"
                          min={1}
                          placeholder={`${t("enter")}${t("quantity")}`}
                          className="!p-1.5"
                          value={item.quantity}
                          onChange={(e) => {
                            const newItems = [...props.values.items];
                            newItems[index].quantity = Number(e.target.value);
                            props.setFieldValue("items", newItems);
                          }}
                        />
                        <Field
                          name={`items[${index}].discount_amount`}
                          as={SharedInputText}
                          label={t("discountAmount")}
                          type="number"
                          placeholder={`${t("enter")}${t("amount")}`}
                          className="!p-1.5"
                          value={item.discount_amount}
                          onChange={(e) => {
                            const newItems = [...props.values.items];
                            newItems[index].discount_amount = Number(
                              e.target.value
                            );
                            props.setFieldValue("items", newItems);
                          }}
                        />
                        <Field
                          name={`items[${index}].tax_amount`}
                          as={SharedDropdown}
                          label={`${t("vat")} ${t("amount")}`}
                          type="number"
                          placeholder={`${t("select")} ${t("vat")}`}
                          options={vat_values}
                          value={item.tax_amount}
                          onChange={(e) => {
                            const newItems = [...props.values.items];
                            newItems[index].tax_amount = Number(e.target.value);
                            props.setFieldValue("items", newItems);
                          }}
                          min={0}
                        />

                        <div className="flex justify-end items-end">
                          <span className="h-[30px] w-full flex justify-end pr-5">
                            <RiDeleteBin5Line
                              size={20}
                              className="cursor-pointer text-red-500"
                              onClick={() => {
                                const newItems = props.values.items.filter(
                                  (_, i) => i !== index
                                );
                                props.setFieldValue("items", newItems);
                              }}
                            />
                          </span>
                        </div>
                      </div>

                      {/* <SharedDropdown
                    label={t("discountType")}
                    name={`items[${index}].discount_type`}
                    options={typeOptions}
                    value={item.discount_type}
                    onChange={(e) => {
                      const newItems = [...props.values.items];
                      newItems[index].discount_type = e.target.value;
                      props.setFieldValue("items", newItems);
                    }}
                  /> */}

                      {/* <SharedDropdown
                    label={t("taxType")}
                    name={`items[${index}].tax_type`}
                    options={typeOptions}
                    value={item.tax_type}
                    onChange={(e) => {
                      const newItems = [...props.values.items];
                      newItems[index].tax_type = e.target.value;
                      props.setFieldValue("items", newItems);
                    }}
                  /> */}

                      {props.errors.items && props.errors.items[index] && (
                        <div className="col-span-4 text-red-500">
                          {Object.values(props.errors.items[index]).map(
                            (error, i) => (
                              <div key={i}>{error}</div>
                              // <div key={i}>
                              //   {typeof error === "string"
                              //     ? error
                              //     : JSON.stringify(error)}
                              // </div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                  {props.errors.items &&
                    typeof props.errors.items === "string" && (
                      <div className="text-red-500">{props.errors.items}</div>
                    )}
                  {/* {console.log(getTotalAmount(props))} */}
                </div>

                <div className="mt-4 ">
                  <CustomSearchDropdown
                    label={t("items")}
                    name={"items"}
                    options={ItemsOptions}
                    placeholder={`${t("select")} ${t("items")}`}
                    className="!w-full"
                    onChange={(e) => {
                      const newItem = {
                        id: Number(e.target.value),
                        patientShare: 0,
                        amount: getAmount(Number(e.target.value)),
                        quantity: 1,
                        discount_type: "fixed",
                        discount_amount: 0,
                        tax_type: "percent",
                        tax_amount: 15,
                      };
                      props.setFieldValue("items", [
                        ...props.values.items,
                        newItem,
                      ]);
                    }}
                  />
                </div>

                <div className="flex justify-between mt-6">
                  <div>
                    <SharedButton
                      icon="pi pi-plus"
                      label={t("CREATECUSTOMERS")}
                      className="!p-1.5 bg-gradient"
                      onClick={() => navigate("/manager/customers")}
                    />
                  </div>

                  <div className="flex gap-6">
                    <SharedButton
                      icon="pi pi-times"
                      label={t("Cancel")}
                      className="!p-1.5 !bg-red-400 hover:!bg-red-400"
                      onClick={() => {
                        //   setCreateDialogVisible(false);
                        navigate("/manager/invoices");
                        props.resetForm();
                      }}
                    />
                    <SharedButton
                      icon="pi pi-check"
                      label={t("Submit")}
                      className="!p-1.5 bg-gradient"
                      onClick={props.handleSubmit}
                    />
                  </div>
                </div>
              </div>

              {/* <div
                className={`${
                  props.values.items.length > 0 ? "w-[30%]" : "hidden"
                }`}
              >
                {props?.values?.items?.length > 0 && (
                  <div className="mt-6 bg-white rounded-lg shadow-md p-6">
                    <h3 className="text-2xl font-semibold mb-4 text-gray-800">
                      Bill Summary
                    </h3>
                    <div className="space-y-4">
                      {props?.values?.items.map((item, index) => (
                        <div key={index} className="bg-gray-50 p-4 rounded-lg">
                          <h4 className="text-lg font-semibold mb-2 text-gray-700">
                            {getLabel(item)}
                            Your Total Bill
                          </h4>
                          <div className="space-y-2">
                            <div className="flex justify-between">
                              <span className="text-gray-600">
                                Amount:
                              </span>
                              <span className="font-semibold">
                                {item.amount}
                              </span>
                            </div>

                            <div className="flex justify-between">
                              <span className="text-gray-600">Quantity:</span>
                              <span className="font-semibold">
                                {item.quantity}
                              </span>
                            </div>

                            <div className="flex justify-between">
                              <span className="text-gray-600">
                                Patient Share:
                              </span>
                              <span className="font-semibold">
                                {item.patientShare}
                              </span>
                            </div>

                            <div className="flex justify-between">
                              <span className="text-gray-600">
                                Discount Amount:
                              </span>
                              <span className="font-semibold">
                                {item.discount_amount}
                              </span>
                            </div>
                            <div className="flex justify-between">
                              <span className="text-gray-600">Tax Amount:</span>
                              <span className="font-semibold">
                                {item.tax_amount}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                      
                      <div className="flex justify-between text-lg font-semibold mt-4">
                        <span className="text-gray-600">Net Total:</span>
                        <span>
                          {props.values.items
                            .reduce(
                              (total, item) =>
                                total +
                                item.amount * item.quantity -
                                item.discount_amount +
                                item.tax_amount,
                              0
                            )
                            .toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div> */}

              <div
                className={`${
                  props.values.items.length > 0 ? "w-[30%]" : "hidden"
                }`}
              >
                {props?.values?.items?.length > 0 && (
                  <div className="mt-6 bg-white rounded-lg shadow-md p-6">
                    <h3 className="text-2xl font-semibold mb-4 text-gray-800">
                      {t("BillSummary")}
                    </h3>
                    <div className="space-y-4">
                      <div className="bg-gray-50 p-4 !pb-2 rounded-lg">
                        <h4 className="text-lg font-semibold mb-4 text-gray-700 text-center">
                          {t("YourTotalBill")}
                        </h4>
                        <div className="space-y-2">
                          <div className="flex justify-between">
                            <span className="text-gray-600">
                              {`${t("Total")} ${t("amount")}`}:
                            </span>
                            <span className="font-semibold">
                              {props.values.items
                                .reduce(
                                  (total, item) =>
                                    total + item.amount * item.quantity,
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </div>

                          <div className="flex justify-between">
                            <span className="text-gray-600">
                              {`${t("Total")} ${t("discount")}`}:
                            </span>
                            <span className="font-semibold">
                              {props.values.items
                                .reduce(
                                  (total, item) => total + item.discount_amount,
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </div>

                          <div className="flex justify-between">
                            <span className="text-gray-600">
                              {`${t("Total")} ${t("vat")}`}:
                            </span>
                            <span className="font-semibold">
                              {props.values.items
                                .reduce(
                                  (total, item) => total + item.tax_amount,
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </div>

                          <div className="flex justify-between text-lg font-semibold mt-4">
                            <span className="text-gray-600">{t("total")}:</span>
                            <span>
                              {props.values.items
                                .reduce(
                                  (total, item) =>
                                    total +
                                    item.amount * item.quantity -
                                    item.discount_amount +
                                    item.tax_amount,
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </div>

                      {/* <div className="flex justify-between text-lg font-semibold mt-4">
                        <span className="text-gray-600">Net Total:</span>
                        <span>
                          {props.values.items
                            .reduce(
                              (total, item) =>
                                total +
                                item.amount * item.quantity -
                                item.discount_amount +
                                item.tax_amount,
                              0
                            )
                            .toFixed(2)}
                        </span>
                      </div> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
}
