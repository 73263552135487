import React, { useCallback, useEffect, useRef } from "react";
import { Field, Formik } from "formik";
import * as yup from "yup";
import SharedInputText from "../../components/Shared/SharedInputText";
import SharedButton from "../../components/Shared/SharedButton";
import { useDispatch, useSelector } from "react-redux";
import {
  createCompanyInvoice,
  createPatientInvoice,
  getCompanyInvoicesList,
} from "../../store/slices/InvoicesSlice";
import SharedDropdown from "../../components/Shared/SharedDropdown";
import CustomSearchDropdown from "../../components/Shared/CustomSearchDropdown";
import { getItems, getPriceListByID } from "../../store/slices/ItemSlice";
import { RiDeleteBin5Line } from "react-icons/ri";
import { getCustomers } from "../../store/slices/CustomerSlice";
import { GetBusinesses } from "../../store/slices/BusinessSlice";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getCurrentDateTime } from "../Transactions/CreateTransaction";
import i18next from "i18next";

export default function CreateCompanyInvoicePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = i18next.language;

  const { user } = useSelector((state) => state.auth);
  const { customers } = useSelector((state) => state.customers);
  const { business } = useSelector((state) => state.business);
  const { items } = useSelector((state) => state.item);

  useEffect(() => {
    dispatch(getCustomers());
    dispatch(GetBusinesses());
  }, [dispatch]);

  function customer(inputData) {
    return Object.values(inputData).map((item) => ({
      id: item.id,
      name: item.name,
    }));
  }

  function businesses(inputData) {
    return Object.values(inputData).map((item) => ({
      id: item.id,
      name: lang === "ar" ? item.name_ar : item.name_en,
    }));
  }

  const paid_values = [
    { value: true, label: "Paid" },
    { value: false, label: "Unpaid" },
  ];
  const payment_method_values = [
    { value: "cash", label: lang === "ar" ? "نقدي" : "Cash" },
    { value: "bank", label: lang === "ar" ? "بنك" : "Bank" },
    { value: "mada", label: lang === "ar" ? "مدى" : "MADA" },
  ];

  const discountType_option = [
    { value: "percent", label: "Percent" },
    { value: "fixed", label: "Fixed" },
  ];
  const taxType_option = [
    { value: "percent", label: "Percent" },
    { value: "fixed", label: "Fixed" },
  ];
  const taxName_option = [{ value: "VAT", label: "VAT" }];
  const vat_values = [
    { value: 15, label: lang === "ar" ? "نعم" : "Yes" },
    { value: 0, label: lang === "ar" ? "لا" : "No" },
  ];

  const initialValues = {
    date: getCurrentDateTime(),
    // amount: 0,
    description: "",
    PaymentMethod: "",
    paid: false,
    customer_id: "",
    branch_id: user?.branch_id,
    business_id: user?.business_id,
    invoice_type: "credit",
    company_id: "",
    // discount_type: "",
    // discount_value: "",
    // tax_type: "",
    // tax_name: "",
    // tax_value: "",
    items: [],
  };

  const validationSchema = yup.object().shape({
    date: yup.string().required(t("required")),
    // amount: yup.number().required("Amount is required"),
    description: yup.string().required(t("required")),
    // paid: yup.bool().required(t("required")),
    PaymentMethod: yup.string().required(t("required")),
    customer_id: yup.number().required(t("required")),
    company_id: yup.number().required(t("required")),
    // discount_type: yup.string().required("Discount Type is required"),
    // discount_value: yup.number().required("Discount Value is required"),
    // tax_type: yup.string().required("Tax Type is required"),
    // tax_name: yup.string().required("Tax Name is required"),
    // tax_value: yup.number().required("Tax Value is required"),
    items: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number().required(t("required")),
          // quantity: yup
          //   .number()
          //   .integer()
          //   .min(1, "Quantity must be at least 1")
          //   .required(t("required")),
          //   patient_share: yup
          //     .number()
          //     .min(0, "Patient share must be non-negative")
          //     .required(t("required")),
          // discount_type: yup.string().required(t("required")),
          // discount_amount: yup
          //   .number()
          //   .min(0, `${t("discountAmount ")}${t(" mustBePositive")}`)
          //   .required(t("required")),
          // tax_type: yup.string().required(t("required")),
          tax_amount: yup
            .number()
            .min(0, "Tax amount must be non-negative")
            .required(t("required")),
          company_tax_type: yup.string().required(t("required")),
          // company_tax_amount: yup
          //   .number()
          //   .min(0, "Company tax amount must be non-negative")
          //   .required(t("required")),
        })
      )
      .min(1, t("required")),
  });

  function convertData(inputData) {
    return Object.values(inputData).map((item) => ({
      id: item.ID,
      name: lang === "ar" ? item.name_ar : item.name_en,
    }));
  }
  const { isLoading } = useSelector((state) => state.invoice);
  const ItemsOptions = convertData(items?.data || []);

  const getLabel = (item) => {
    const object = ItemsOptions.find((itm) => Number(itm.id) === item.id);
    return object ? object.name : "Unknown Item";
  };

  const getAmount = (itemId) => {
    const item = items?.data?.find((item) => item.ID === itemId);
    return item ? item?.price : 0;
  };

  const getTotalAmount = (formikProps) => {
    return formikProps?.values?.items.reduce(
      (acc, transaction) =>
        acc + Number(transaction?.quantity) * Number(transaction?.amount),
      0
    );
  };
  const GetTotalDiscount = (formikProps) => {
    const value = formikProps?.values?.items.reduce(
      (acc, transaction) => acc + transaction?.discount_amount,
      0
    );

    return formikProps?.values?.AppoinmentType === "Cash"
      ? getTotalAmount(formikProps) - value
      : getTotalAmount(formikProps) - 100 - value;
  };
  //   const GetPatientShare = (formikProps, value) => {
  //     const total = formikProps?.values?.items.reduce(
  //       (acc, transaction) => acc + transaction?.patientShare,
  //       0
  //     );
  //     const discount = formikProps?.values?.items.reduce(
  //       (acc, transaction) => acc + transaction?.discount_amount,
  //       0
  //     );
  //     if (value === "ps") {
  //       return total;
  //     } else if (value === "ins")
  //       return getTotalAmount(formikProps) - total - discount;
  //   };
  const GetNetTotal = (formikProps) => {
    return (
      GetTotalDiscount(formikProps) + (GetTotalDiscount(formikProps) * 15) / 100
    );
  };

  // const getNationality = (formik) => {
  //   const patient = customers?.find(
  //     (item) => item.id === formik?.values?.customer_id
  //   );

  //   return patient ? patient.nationality : null;
  // };
  const getNationality = (formik) => {
    const customersArray = Object.values(customers);
    const patient = customersArray.find(
      (item) => item.id === formik?.values?.customer_id
    );
    return patient ? patient.nationality : null;
  };

  const getTotalPatientShare = (formikProps) => {
    return formikProps.values.items.reduce(
      (acc, item) => acc + Number(item.patient_share),
      0
    );
  };
  const handleCompanySelect = (value) => {
    fetchPriceList(value);
  };
  const prevPriceListIdRef = useRef();
  const fetchPriceList = useCallback((id) => {
    if (id && id !== prevPriceListIdRef.current) {
      console.log("Fetching price list for ID:", id);
      dispatch(getPriceListByID({ payer_id: String(id?.target?.value) }));
      prevPriceListIdRef.current = id;
    }
  }, []);
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          const resultAction = await dispatch(createCompanyInvoice(values));
          if (createCompanyInvoice.fulfilled.match(resultAction)) {
            const Pdata = {
              date: values.date,
              amount: values.amount,
              description: values.description,
              paid: values.paid,
              customer_id: values.customer_id,
              branch_id: values.branch_id,
              business_id: values.business_id,
              invoice_type: values.invoice_type,
              items: values.items,
            };
            // dispatch(createPatientInvoice(Pdata));
            //   setCreateDialogVisible(false);
            resetForm();
            navigate("/manager/insuranceInvoice");
            dispatch(getCompanyInvoicesList({ page: 1, per_page: 10 }));
          }
        }}
      >
        {(props) => (
          <>
            <div className="flex rounded-md bg-gray-100 p-6 w-full gap-4">
              {/* {console.log(getNationality(props))} */}
              <div
                className={` ${
                  props.values.items.length > 0 ? "w-[70%]" : "w-full"
                }`}
              >
                <h2 className="text-3xl font-bold mb-6 text-gray-800">
                  {`${t("create")} ${t("creditinvoice")}`}
                </h2>

                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 w-full mb-3">
                  <SharedInputText
                    type={"datetime-local"}
                    label={t("date")}
                    name={"date"}
                    placeholder="Enter Date"
                    className="!p-1.5"
                  />

                  {/* <SharedInputText
                type="number"
                label="Amount"
                name="amount"
                placeholder="Enter Amount"
                className="!p-1.5"
              /> */}

                  <CustomSearchDropdown
                    label={t("customers")}
                    name="customer_id"
                    placeholder={`${t("select")} ${t("customers")}`}
                    // options={customer(customers)}
                    options={customer(customers || [])}
                    className={"!w-full"}
                  />

                  <CustomSearchDropdown
                    label={t("payer")}
                    name="company_id"
                    placeholder={`${t("select")} ${t("payer")}`}
                    options={businesses(business || [])}
                    className={"!w-full"}
                    onChange={handleCompanySelect}
                  />

                  <SharedDropdown
                    label={t("paymentMethod")}
                    name={"PaymentMethod"}
                    options={payment_method_values}
                  />

                  <SharedInputText
                    label={t("description")}
                    name="description"
                    placeholder={`${t("enter")} ${t("description")}`}
                    className="!p-1.5"
                  />

                  {/* <SharedDropdown
                    label={t("paid")}
                    name="paid"
                    options={paid_values}
                  /> */}

                  {/* <SharedDropdown
                  label="Discount Type"
                  name="discount_type"
                  options={discountType_option}
                />

                <SharedInputText
                  type="number"
                  label="Discount Value"
                  name="discount_value"
                  placeholder="Enter Discount Value"
                  className="!p-1.5"
                />

                <SharedDropdown
                  label="Tax Type"
                  name="tax_type"
                  options={taxType_option}
                />

                <SharedDropdown
                  label="Tax Name"
                  name="tax_name"
                  options={taxName_option}
                />

                <SharedInputText
                  type="number"
                  label="Tax Value"
                  name="tax_value"
                  placeholder="Enter Tax Value"
                  className="!p-1.5"
                /> */}
                </div>

                <div className="max-h-[200px] overflow-y-auto scrollbar">
                  {props.values.items.map((item, index) => (
                    <div
                      key={index}
                      className="grid grid-cols-1 gap-2 mb-4 border-t border-b py-4"
                    >
                      <div className="flex gap-4">
                        <div className="flex">
                          <span className="font-semibold">{t("name")}: </span>
                          <div className="px-2">{getLabel(item)}</div>
                        </div>
                        <div className="flex">
                          <span className="font-semibold">
                            {t("ItemAmount")}:{" "}
                          </span>
                          <div className="px-2">{getAmount(item.id)}</div>
                        </div>
                      </div>

                      <div className="grid grid-cols-6 gap-4">
                        <Field
                          name={`items[${index}].quantity`}
                          as={SharedInputText}
                          label={t("quantity")}
                          type="number"
                          placeholder={`${t("select")} ${t("quantity")}`}
                          className="!p-1.5"
                        />
                        <Field
                          name={`items[${index}].patient_share`}
                          as={SharedInputText}
                          label={t("patientShare")}
                          type="number"
                          min={0}
                          placeholder={`${t("enter")} ${t("patientShare")}`}
                          className="!p-1.5"
                        />
                        <Field
                          name={`items[${index}].discount_amount`}
                          as={SharedInputText}
                          label={t("discountAmount")}
                          type="number"
                          min={0}
                          placeholder={`${t("enter")} ${t("discountAmount")}`}
                          className="!p-1.5"
                        />
                        {/* <Field
                          name={`items[${index}].tax_amount`}
                          as={SharedInputText}
                          label={t("taxAmount")}
                          type="number"
                          placeholder={`${t("enter")} ${t("taxAmount")}`}
                          className="!p-1.5"
                        /> */}
                        <Field
                          name={`items[${index}].tax_amount`}
                          as={SharedDropdown}
                          label={`${t("vat")} ${t("amount")}`}
                          type="number"
                          placeholder={`${t("select")} ${t("vat")}`}
                          options={vat_values}
                          value={item.tax_amount}
                          onChange={(e) => {
                            const newItems = [...props.values.items];
                            newItems[index].tax_amount = Number(e.target.value);
                            props.setFieldValue("items", newItems);
                          }}
                          min={0}
                        />
                        {/* <Field
                          name={`items[${index}].company_tax_amount`}
                          as={SharedInputText}
                          label={t("companyTaxAmount")}
                          type="number"
                          placeholder={`${t("enter")} ${t("companyTaxAmount")}`}
                          className="!p-1.5"
                        /> */}
                        <div className="flex justify-end items-end ">
                          <span className="h-[30px] w-full flex justify-end pr-5">
                            <RiDeleteBin5Line
                              size={20}
                              className="cursor-pointer text-red-500"
                              onClick={() => {
                                const newItems = props.values.items.filter(
                                  (_, i) => i !== index
                                );
                                props.setFieldValue("items", newItems);
                              }}
                            />
                          </span>
                        </div>
                      </div>

                      {props.errors.items && props.errors.items[index] && (
                        <div className="col-span-4 text-red-500">
                          {Object.values(props.errors.items[index]).map(
                            (error, i) => (
                              <div key={i}>{error}</div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                  {props.errors.items &&
                    typeof props.errors.items === "string" && (
                      <div className="text-red-500">{props.errors.items}</div>
                    )}
                  {console.log(getTotalAmount(props))}
                </div>

                <div className="mt-4">
                  <CustomSearchDropdown
                    label={t("items")}
                    name="items"
                    placeholder={`${t("select")} ${t("items")}`}
                    className="!w-full"
                    options={ItemsOptions}
                    onChange={(e) => {
                      const newItem = {
                        id: Number(e.target.value),
                        quantity: 1,
                        amount: getAmount(Number(e.target.value)),
                        patient_share: 0,
                        discount_type: "fixed",
                        discount_amount: 0,
                        tax_type: "percent",
                        tax_amount: 15,
                        company_tax_type: "percent",
                        company_tax_amount: 0,
                      };

                      props.setFieldValue("items", [
                        ...props.values.items,
                        newItem,
                      ]);
                    }}
                  />
                </div>

                <div className="flex justify-between mt-6">
                  <div className="flex gap-6">
                    <SharedButton
                      icon="pi pi-plus"
                      label={t("CREATECUSTOMERS")}
                      className="!p-1.5 bg-gradient"
                      onClick={() => navigate("/manager/customers")}
                    />
                    <SharedButton
                      icon="pi pi-plus"
                      label={`${t("create")} ${t("payer")}`}
                      className="!p-1.5 bg-gradient"
                      onClick={() => navigate("/manager/business")}
                    />
                  </div>
                  <div className="flex gap-6">
                    <SharedButton
                      icon="pi pi-times"
                      label={t("Cancel")}
                      className="!p-1.5 !bg-red-400 hover:!bg-red-400"
                      onClick={() => {
                        navigate("/manager/insuranceInvoice");
                        props.resetForm();
                      }}
                    />
                    <SharedButton
                      icon="pi pi-check"
                      label={t("Submit")}
                      className="!p-1.5 bg-gradient"
                      onClick={props.handleSubmit}
                    />
                  </div>
                </div>
              </div>

              <div
                className={`${
                  props.values.items.length > 0 ? "w-[30%]" : "hidden"
                }`}
              >
                {props?.values?.items?.length > 0 && (
                  <div className="mt-6 bg-white rounded-lg shadow-md p-6">
                    <h3 className="text-2xl font-semibold mb-4 text-gray-800">
                      {t("BillSummary")}
                    </h3>
                    <div className="space-y-4">
                      <div className="bg-gray-50 p-4 !pb-2 rounded-lg">
                        <h4 className="text-lg font-semibold mb-4 text-gray-700 text-center">
                          {`${t("Customer")} ${t("bill")}`}
                        </h4>
                        <div className="space-y-2">
                          {/* <div className="flex justify-between">
                            <span className="text-gray-600">Total Amount:</span>
                            <span className="font-semibold">
                              {props.values.items
                                .reduce(
                                  (total, item) =>
                                    total +
                                    item.amount * item.quantity -
                                    item.discount_amount,
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </div> */}
                          <div className="flex justify-between">
                            <span className="text-gray-600">
                              {`${t("Total")} ${t("amount")}`}:
                            </span>
                            <span className="font-semibold">
                              {props.values.items
                                .reduce(
                                  (total, item) =>
                                    total + item.amount * item.quantity,
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </div>

                          {/* <div className="flex justify-between">
                            <span className="text-gray-600">Patient Share:</span>
                            <span className="font-semibold">
                              {props.values.items
                                .reduce(
                                  (total, item) =>
                                    total +
                                    // item.amount * item.quantity -
                                    item.patient_share,
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </div> */}
                          <div className="flex justify-between">
                            <span className="text-gray-600">
                              {t("patientShare")}:
                            </span>
                            <span className="font-semibold">
                              {props.values.items.reduce(
                                (total, item) => total + item?.patient_share,
                                0
                              )}
                            </span>
                          </div>

                          {/* <div className="flex justify-between">
                            <span className="text-gray-600">
                              Total Discount:
                            </span>
                            <span className="font-semibold">
                              {props.values.items
                                .reduce(
                                  (total, item) => total + item.discount_amount,
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </div> */}

                          {/* <div className="flex justify-between">
                            <span className="text-gray-600">Total Tax:</span>
                            <span className="font-semibold">
                              {getNationality(props) === "saudi"
                                ? props.values.items
                                    .reduce((total, item) => total, 0)
                                    .toFixed(2)
                                : props.values.items
                                    .reduce(
                                      (total, item) => total + item.tax_amount,
                                      0
                                    )
                                    .toFixed(2)}
                            </span>
                          </div> */}
                          {/* {getNationality(props) !== "saudi" && ( */}
                          <div className="flex justify-between">
                            <span className="text-gray-600">
                              {`${t("Total")} ${t("vat")}`}:
                            </span>
                            <span className="font-semibold">
                              {props.values.items
                                .reduce(
                                  (total, item) => total + item.tax_amount,
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </div>
                          {/* )} */}

                          {/* <div className="flex justify-between">
                                <span className="text-gray-600">Patient Share:</span>
                                <span className="font-semibold">
                                    {getTotalPatientShare(props).toFixed(2)}
                                </span>
                                </div> */}

                          {/* <div className="flex justify-between text-lg font-semibold mt-4">
                            <span className="text-gray-600">Net Total:</span>
                            <span>
                              {getNationality(props) === "saudi"
                                ? props.values.items.reduce(
                                    (total, item) =>
                                      total + item.amount * item.quantity,
                                    0
                                  )
                                : props.values.items
                                    .reduce(
                                      (total, item) =>
                                        total +
                                        item.amount * item.quantity -
                                        item.discount_amount +
                                        item.tax_amount,
                                      0
                                    )
                                    .toFixed(2)}
                            </span>
                          </div> */}
                          <div className="flex justify-between text-lg font-semibold mt-4">
                            <span className="text-gray-600">{t("total")}:</span>
                            <span>
                              {(getNationality(props) === "saudi"
                                ? getTotalPatientShare(props)
                                : getTotalPatientShare(props) +
                                  props.values.items.reduce(
                                    (total, item) => total + item.tax_amount,
                                    0
                                  )
                              ).toFixed(2)}
                            </span>
                          </div>
                          {/* <div className="flex justify-between text-lg font-semibold mt-4">
                                <span className="text-gray-600">Net Total:</span>
                                <span>
                                    {getTotalPatientShare(props).toFixed(2)}
                                </span>
                                </div> */}
                        </div>
                      </div>

                      {/* <div className="bg-gray-50 p-4 !pb-2 rounded-lg mt-5">
                        <h4 className="text-lg font-semibold mb-4 text-gray-700 text-center">
                          Company Bill
                        </h4>
                        <div className="space-y-2">
                          <div className="flex justify-between">
                            <span className="text-gray-600">Total Amount:</span>
                            <span className="font-semibold">
                              {props.values.items
                                .reduce(
                                  (total, item) =>
                                    total + item.amount * item.quantity,
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </div>

                          <div className="flex justify-between">
                            <span className="text-gray-600">
                              Total Discount:
                            </span>
                            <span className="font-semibold">
                              {props.values.items
                                .reduce(
                                  (total, item) => total + item.discount_amount,
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </div>

                          <div className="flex justify-between">
                            <span className="text-gray-600">Total Tax:</span>
                            <span className="font-semibold">
                              {props.values.items
                                .reduce(
                                  (total, item) => total + item.tax_amount,
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </div>
                          <div className="flex justify-between text-lg font-semibold mt-4">
                            <span className="text-gray-600">Net Total:</span>
                            <span>
                              {props.values.items
                                .reduce(
                                  (total, item) =>
                                    total +
                                    item.amount * item.quantity -
                                    item.discount_amount +
                                    item.tax_amount,
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </div> */}
                      <div className="bg-gray-50 p-4 !pb-2 rounded-lg mt-5">
                        <h4 className="text-lg font-semibold mb-4 text-gray-700 text-center">
                          {`${t("Company")} ${t("bill")}`}
                        </h4>
                        <div className="space-y-2">
                          <div className="flex justify-between">
                            <span className="text-gray-600">
                              {`${t("Total")} ${t("amount")}`}:
                            </span>
                            <span className="font-semibold">
                              {props.values.items
                                .reduce(
                                  (total, item) =>
                                    total + item.amount * item.quantity,
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </div>

                          <div className="flex justify-between">
                            <span className="text-gray-600">
                              {`${t("Total")} ${t("payable")}`}:
                            </span>
                            <span className="font-semibold">
                              {props.values.items
                                .reduce(
                                  (total, item) =>
                                    total +
                                    item.amount * item.quantity -
                                    item.patient_share,
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </div>

                          <div className="flex justify-between">
                            <span className="text-gray-600">
                              {`${t("Total")} ${t("discount")}`}:
                            </span>
                            <span className="font-semibold">
                              {props.values.items.reduce(
                                (total, item) => total + item.discount_amount,
                                0
                              )}
                            </span>
                          </div>

                          <div className="flex justify-between">
                            <span className="text-gray-600">
                              {`${t("Total")} ${t("vat")}`}:
                            </span>
                            <span className="font-semibold">
                              {props.values.items
                                .reduce(
                                  (total, item) => total + item.tax_amount,
                                  0
                                )
                                .toFixed(2)}
                            </span>
                          </div>

                          {/* <div className="flex justify-between">
      <span className="text-gray-600">Patient Share:</span>
      <span className="font-semibold">
        -{getTotalPatientShare(props).toFixed(2)}
      </span>
    </div> */}

                          <div className="flex justify-between text-lg font-semibold mt-4">
                            <span className="text-gray-600">{t("total")}:</span>
                            <span>
                              {(
                                props.values.items.reduce(
                                  (total, item) =>
                                    total +
                                    item.amount * item.quantity -
                                    item.discount_amount +
                                    item.tax_amount,
                                  0
                                ) - getTotalPatientShare(props)
                              ).toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </div>

                      {/* <div className="flex justify-between text-lg font-semibold mt-4">
                        <span className="text-gray-600">Net Total:</span>
                        <span>
                          {props.values.items
                            .reduce(
                              (total, item) =>
                                total +
                                item.amount * item.quantity -
                                item.discount_amount +
                                item.tax_amount,
                              0
                            )
                            .toFixed(2)}
                        </span>
                      </div> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
}
