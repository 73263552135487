import React from "react";
import { useTranslation } from "react-i18next";

export default function IncomeCard({ incomeStatement }) {
  const { t } = useTranslation();
  return (
    <div>
      <div className="bg-white rounded-lg shadow-md  w-full my-5">
        <div className="text-black rounded-t-lg bg-[#c5c5c7]">
          <h2 className="text-lg font-semibold p-1 mb-4">
            {t("IncomeStatement")}
          </h2>
        </div>
        <div className="space-y-3 p-3">
          <div className="flex justify-between">
            <span className="font-medium">{t("Expenses")}:</span>
            <span>{incomeStatement?.expenses} {t("SAR")}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium">{t("Revenue")}:</span>
            <span>{incomeStatement?.revenue} {t("SAR")}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium">{t("Income")}:</span>
            <span>{incomeStatement?.net_income} {t("SAR")}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
