import axios from "axios";
import { BACKEND_API } from "./apiConfig";
import { store } from "../store/index"; // Import the Redux store
import { logout } from "../store/slices/AuthSlice";

const lang = localStorage.getItem("i18nextLng");

const validSupergitHIS = axios.create({
  baseURL: BACKEND_API,
  headers: {
    Accept: `application/json`,
    "Accept-Language": `${lang}`,
  },
});

validSupergitHIS.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.auth.token;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

validSupergitHIS.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch(logout());
    }
    return Promise.reject(error);
  }
);

export default validSupergitHIS;
