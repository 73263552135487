import React from "react";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { useField } from "formik";

export default function SharedInputText({
  placeholder = "",
  label = "",
  name = "",
  className = "",
  isIcon = false,
  icon,
  iconPos = "left",
  iconClassName = "",
  defaultValue = "",
  type = "text",
  ...props
}) {
  const [field, meta, helpers] = useField(name);

  React.useEffect(() => {
    if (type === "date" && !field.value) {
      const today = new Date().toISOString().split("T")[0];
      helpers.setValue(today);
    }
  }, [type, field.value, helpers]);

  return (
    <div className="flex flex-col gap-1 w-full">
      <label htmlFor={name} className="text-base font-medium">
        {label}
      </label>
      <IconField iconPosition={iconPos} className="relative">
        {isIcon && (
          <div
            className={`absolute top-1/2 transform -translate-y-1/2 ${
              iconPos === "left" ? "left-3" : "left-auto right-3"
            } text-primary ${iconClassName}`}
          >
            {icon}
          </div>
        )}
        <InputText
          id={name}
          placeholder={placeholder}
          type={type}
          {...(type === "number" ? { min: 0 } : {})}
          {...field}
          {...props}
          className={`w-full p-2 rounded-md hover:border-primary !outline-none !shadow-none ${className} ${
            isIcon && iconPos === "left"
              ? "!pl-10"
              : iconPos === "right"
              ? "!pr-10"
              : null
          } `}
        />
      </IconField>
      {meta.error && meta.touched && (
        <p className="text-sm text-red-500">{meta.error}</p>
      )}
    </div>
  );
}
