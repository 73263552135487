import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SharedButton from "../../components/Shared/SharedButton";
import CreateTransaction from "./CreateTransaction";
import SharedTooltip from "../../components/Shared/SharedTooltip";
import TransactionsTable from "./TransactionsTable";
import { getTransactions } from "../../store/slices/TransactionsSlice";
import Loader from "../../Loader";
import { useTranslation } from "react-i18next";

export default function TransactionsMain() {
  const dispatch = useDispatch();
  const [createDialogVisible, setCreateDialogVisible] = useState(false);

  useEffect(() => {
    dispatch(getTransactions({ page: 1, per_page: 10 }));
  }, [dispatch]);

  const { transactions, isLoading, page, per_page, total, total_record } = useSelector(
    (state) => state.transaction );

  const { t } = useTranslation();
  
  return (
    <div className="">
      <div className="flex justify-between flex-col gap-3 sm:gap-0 sm:flex-row mb-3">
        <SharedTooltip
          content= {t("Keep_track_of_your_transactions")}
          position="left"
          id="transactions"
        />
        <span
          data-tooltip-id="transactions"
          className="font-semibold text-black/70 text-xl mt-2 "
        >
          {t("JournalEntries")}
          <span className="italic text-lg ml-1">
            ({total_record || 0}
            <span className="text-xs font-bold italic"> {t("Entries")}</span>)
          </span>
        </span>

        <SharedButton
          label={t("CreateJournalEntries")}
          icon="pi pi-plus"
          onClick={() => setCreateDialogVisible(true)}
          className="bg-gradient"
        />
      </div>

      <CreateTransaction
        createDialogVisible={createDialogVisible}
        setCreateDialogVisible={setCreateDialogVisible}
      />
      <TransactionsTable data={transactions} />
      <Loader showLoader={isLoading} />
    </div>
  );
}
