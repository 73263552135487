import { Formik } from "formik";
import React, { useEffect } from "react";
import * as yup from "yup";
import { Dialog } from "primereact/dialog";
import SharedDropdown from "../../components/Shared/SharedDropdown";
import SharedButton from "../../components/Shared/SharedButton";
import { CreateNewPurchasedPayment, getPurchasedEntryList } from "../../store/slices/PurchaseSlice";
import SharedInputText from "../../components/Shared/SharedInputText";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import CustomSearchDropdown from "../../components/Shared/CustomSearchDropdown";
import { getCashBankAccounts} from "../../store/slices/AcountsSlice";

export default function ReceivePayment({ selectedEntries, open, setOpen }) {
  function convertData(data) {
    const datatoreturn = data?.map((dt) => ({
      purchase_id: dt.id,
      total_amount: dt.total_amount,
    }));
    return datatoreturn;
  }

  const initialValues = {
    data: convertData(selectedEntries),
    payment_date: "",
    payment_method: "",
    account_code: "",
    notes: "",
  };
  // console.log("DATAAaa: ", initialValues);

  const dispatch = useDispatch();
  const lang = i18next.language;
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    payment_date: yup.string().required(t("required")),
    payment_method: yup.string().required(t("required")),
    account_code: yup.string().required(t("required")),
    // credit_account_id: yup.string().required(t("required")),
    // debit_account_id: yup.string().required(t("required")),
    notes: yup.string().required(t("required")),
  });

  const paymentOption = [
    { value: "bank", label: "Bank" },
    { value: "cash", label: "Cash" },
    { value: "mada", label: "MADA" },
  ];

  function handleSubmit(values, { resetForm }) {
    dispatch(CreateNewPurchasedPayment(values)).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        setOpen(false);
        resetForm();
        dispatch(getPurchasedEntryList({ page: 1, per_page: 10 }));
      }
    });
  }
  useEffect(() => {
    // dispatch(getListAccounts());
    dispatch(getCashBankAccounts());
  }, []);

  const { isLoading } = useSelector((state) => state.purchase);
  const { user } = useSelector((state) => state.auth);
  const { listAccounts, cashBankAccounts } = useSelector((state) => state.acount);

  const listAccountsvalues = cashBankAccounts?.data
    ? Object.entries(cashBankAccounts.data).map(([id, account]) => ({
        id: Number(account?.code),
        name: lang === "ar" ? account.name_ar : account.name_en,
      }))
    : [];

  return (
    <>
      <Dialog
        header={t("RecordPayment")}
        visible={open}
        onHide={() => {
          if (!open) return;
          setOpen(false);
        }}
        pt={{
          root: { className: "w-full lg:w-10/12 mx-4 overflow-auto" },
          header: { className: "!p-2 !bg-darkBg !text-white" },
          content: { className: "!p-3" },
          closeButton: {
            className: "hover:!bg-black/20 !outline-none !shadow-none",
          },
          closeButtonIcon: { className: "!text-white" },
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <>
              <div className="grid grid-cols-4 gap-4">
                <SharedInputText
                  label={t("payment_date")}
                  name="payment_date"
                  placeholder={`${t("enter")} ${t("payment_date")}`}
                  className="!p-1.5"
                  type={"date"}
                />
                <SharedDropdown
                  label={t("paymentMethod")}
                  placeholder={`${t("select")} ${t("paymentMethod")}`}
                  name="payment_method"
                  options={paymentOption}
                />

                <CustomSearchDropdown
                  label={`${t("cashBank")} ${t("account")}`}
                  className="!w-full"
                  name={"account_code"}
                  options={listAccountsvalues}
                  placeholder={`${t("select")} ${t("account")}`}
                />

                <SharedInputText
                  label={t("description")}
                  name="notes"
                  placeholder={`${t("enter")} ${t("description")}`}
                  className="!p-1.5"
                />
              </div>
              <div className="flex justify-end gap-6 mt-6">
                <SharedButton
                  icon="pi pi-times"
                  label={t("Cancel")}
                  className="!p-1.5 !bg-red-400 hover:!bg-red-400"
                  onClick={() => {
                    setOpen(false);
                    props.resetForm();
                  }}
                />
                <SharedButton
                  disabled={isLoading}
                  icon="pi pi-check"
                  label={t("Submit")}
                  className="!p-1.5 bg-gradient"
                  onClick={props.handleSubmit}
                />
              </div>
            </>
          )}
        </Formik>
      </Dialog>

      <Loader showLoader={isLoading} />
    </>
  );
}
