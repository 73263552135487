import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import BranchTable from "./BranchTable";
import CreateBranch from "./CreateBranch";
import SharedTooltip from "../../components/Shared/SharedTooltip";
import SharedButton from "../../components/Shared/SharedButton";
import Loader from "../../Loader";
import { getBranchList } from "../../store/slices/BusinessSlice";

export default function BranchMain() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBranchList({ page: 1, per_page: 10 }));
  }, []);

  const [createDialogVisible, setCreateDialogVisible] = useState(false);
  const { branchList, isLoading } = useSelector((state) => state.business);

  return (
    <>
      <div className="flex justify-between flex-col gap-3 sm:gap-0 sm:flex-row mb-3">
        <SharedTooltip
          content={t("Keep_track_of_your_branches")}
          position="left"
          id="Items"
        />
        <span
          data-tooltip-id="Items"
          className="font-semibold text-black/70 text-xl mt-2 "
        >
          {t("branches")}
          <span className="italic text-lg ml-1">
            ({branchList?.total_record || 0}
            <span className="text-xs font-bold italic">{t("Entries")} </span>)
          </span>
        </span>
        <SharedButton
          label={t("CreateBranch")}
          icon="pi pi-plus"
          onClick={() => setCreateDialogVisible(true)}
          className="bg-gradient"
        />
      </div>
      <BranchTable data={branchList} />
      <CreateBranch
        setCreateDialogVisible={setCreateDialogVisible}
        createDialogVisible={createDialogVisible}
        dispatch={dispatch}
      />
      <Loader showLoader={isLoading} />
    </>
  );
}
